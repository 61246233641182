
import {
	IonContent,
	IonHeader,
	IonPage,
	IonAvatar,
	IonLabel,
	IonCard,
	IonButton,
	IonCol,
	IonRow,
	IonGrid,
	IonList,
	IonItem,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	modalController,
	IonImg,
	IonToolbar,
	IonBackButton,
	IonButtons,
	IonTitle,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Navbar from "../components/Navbar.vue";
import Bottombar from "../components/Bottombar.vue";
import {
	calendar,
	personCircle,
	filterCircleOutline,
	playCircleOutline,
	albumsOutline,
	barbellOutline,
	starSharp,
	menuOutline,
	trophyOutline,
} from "ionicons/icons";
import moment, { utc, Moment } from "moment";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import Modal from "./modals/modalVideo.vue";
import FilterModal from "./modals/modalFilter.vue";
import { ActionTypes as ScheduleActions } from "../store/modules/schedule";
import { useRouter } from "vue-router";
import { store } from "../store";
import { ActionTypes as Content } from "../store/modules/content";

export default defineComponent({
	name: "Schedule",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonAvatar,
		IonLabel,
		IonCard,
		IonCol,
		IonRow,
		IonGrid,
		IonList,
		IonItem,
		IonInfiniteScroll,
		IonInfiniteScrollContent,
		IonImg,
		IonButton,
		IonToolbar,
		IonBackButton,
		IonButtons,
		IonTitle,
		Navbar,
		Bottombar,
	},

	setup() {
		return {
			playCircleOutline,
			filterCircleOutline,
			albumsOutline,
			barbellOutline,
			calendar,
			personCircle,
			starSharp,
			trophyOutline,
			menuOutline,
		};
	},
	beforeMount: async function () {
		this.loading = true;
		this.generateDays();
		store.dispatch(ScheduleActions.fetchUserSchedule, {
			user: store.getters.userData.uid,
			start: this.days[0],
			end: this.days[5],
		});
		this.classes = store.getters.getClassesForDay(this.days[0]);
		this.filteredClasses = store.getters.getClassesForDay(this.days[0]);
		this.daySelected = this.days[0];
	},
	data() {
		return {
			days: [] as Moment[],
			userName: "",
			isAuth: false,
			classes: [],
			daySelected: {} as Moment,
			filteredClasses: [],
			selectedCategory: "",
			loading: false,
			toggleFilter: false,
			toggleValues: {
				live: false,
				onDemand: false,
				liveReplay: false,
				minute10: false,
				minute30: false,
				minute45: false,
				minute60: false,
				// low: false,
				// moderate: false,
				// high: false,
			},
		};
	},
	computed: {
		userSchedule() {
			return store.getters.getUserSchedule;
		},
		isWeb() {
			return store.getters.isWeb;
		},
		categories() {
			return store.getters.getCategories;
		},
	},

	watch: {
		userSchedule(oldSchedule, newSchedule) {
			this.classes = store.getters.getClassesForDay(this.daySelected);
			this.filteredClasses = store.getters.getClassesForDay(this.daySelected);
			this.loading = false;
		},
	},
	async created() {
		this.userName = await store.getters.userName;
		this.isAuth = await store.getters.auth;
	},
	methods: {
		isRegistered(classID: string) {
			const isScheduled = this.userSchedule.filter(
				(scheduleItem: firebase.firestore.DocumentData) => {
					return scheduleItem.classID === classID;
				}
			);

			if (isScheduled.length === 0) {
				return false;
			}

			return true;
		},
		generateDays() {
			for (let i = 0; i < 6; i++) {
				this.days.push(moment().add(i, "days"));
			}
		},
		selectDay(index: number) {
			this.classes = store.getters.getClassesForDay(this.days[index]);
			this.filteredClasses = store.getters.getClassesForDay(this.days[index]);
			this.daySelected = this.days[index];
		},
		toLocalTime(time: Moment) {
			const t = utc(time).local();
			return t;
		},
		async openModal(
			classs: firebase.firestore.DocumentData,
			isScheduled: boolean
		) {
			const modal = await modalController.create({
				component: Modal,
				cssClass: "modal-custom",
				componentProps: {
					title: "Join " + classs.name,
					content: classs.description,
					videoimage: classs.imgURL,
					eventdate: classs.date,
					eventname: classs.name,
					trainer: classs.trainerData.name,
					isLive: classs.isLive,
					video: classs,
					isScheduled: this.isRegistered(classs.classID),
				},
			});
			return modal.present();
		},
		changeCategory(categoryName: string) {
			// Implement toogle filter
			if (this.selectedCategory !== categoryName) {
				this.filteredClasses = this.classes.filter((classObject: any) => {
					return classObject.categoryName === categoryName;
				});
				this.selectedCategory = categoryName;
			} else {
				this.filteredClasses = this.toggleFilter
					? this.filteredClasses
					: this.classes;
				this.selectedCategory = "";
			}

			// this.$router.push(`/videos/${categoryName}`);
		},
		// Setter for the filter
		setFilter(finalResult: any) {
			this.filteredClasses = finalResult;
			// console.log("Set Filter", this.filteredClasses);
			this.toggleFilter = true;
		},
		// Clear Filter
		showAllClasses() {
			this.filteredClasses = this.classes;
			// console.log("Filtered Calssses", this.filteredClasses);
			this.toggleFilter = false;
		},
		toggleHandler(toggleValues: any) {
			let filteredToggleValues = this.toggleValues;

			// console.log("Initial Toggle States", filteredToggleValues);
			// console.log("Toggle States", toggleValues);
			filteredToggleValues = toggleValues;
			// console.log("After Binding", filteredToggleValues);
			// Update selections
			this.toggleValues = filteredToggleValues;

			const toggleCheck =
				this.toggleValues["live"] ||
				this.toggleValues["onDemand"] ||
				this.toggleValues["liveReplay"] ||
				this.toggleValues["minute10"] ||
				this.toggleValues["minute30"] ||
				this.toggleValues["minute45"] ||
				this.toggleValues["minute60"];

			// Check if all Toggles are set to false (XOR)
			if (!toggleCheck) {
				this.toggleFilter = false;
			}
		},
		// Filter Modal
		async openFilterModal() {
			const modal = await modalController.create({
				component: FilterModal,
				componentProps: {
					classes: this.classes,
					setFilter: this.setFilter,
					showAllClasses: this.showAllClasses,
					toggleHandler: this.toggleHandler,
					toggleValuesInitial: this.toggleValues,
				},
			});
			return modal.present();
		},
	},
});
