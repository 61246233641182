import { getters, Getters } from "./getters";
import { mutations, Mutations } from "./mutations";
import { actions, Actions, ActionTypes } from "./actions";
import {
	Store as VuexStore,
	Module,
	CommitOptions,
	DispatchOptions,
} from "vuex";
import { RootState } from "@/store";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";

// TO MODIFY THE STORE
// (A) - Change state  ./
// (B) - Change mutations ./mutations.ts
// (C) - Modify actions ./actions.ts
// (D) - Modify getters ./getters.ts

//[A.1] Include the value in the state interface
// ==> x: TYPE_OF_X
interface State {
	programs: firebase.firestore.DocumentData[];
	//other state typings
}

//[A.2] declare the state object
// ==> x: VALUE_OF_X
const state: State = {
	programs: [],
};

//define and export the module
const programModule: Module<State, RootState> = {
	state,
	mutations,
	actions,
	getters,
};

export { State, ActionTypes, Store };
export default programModule;

// !!! Store typing config !!!
type Store<S = State> = Omit<
	VuexStore<S>,
	"commit" | "getters" | "dispatch"
> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>;
} & {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>;
	};
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>;
};
