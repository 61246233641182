import { Drivers, Storage } from "@ionic/storage";
import * as CordovaSQLiteDriver from "localforage-cordovasqlitedriver";

const localStore = new Storage({
	driverOrder: [
		CordovaSQLiteDriver._driver,
		Drivers.IndexedDB,
		Drivers.LocalStorage,
	],
});
localStore.create();

export default localStore;
