import { MutationTree } from "vuex";
import { State } from "./index";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { Mutations } from "./types";

export enum MutationTypes {
	SET_TRAINERS = "SET_TRAINERS",
	SET_CURRENT_TRAINER = "SET_CURRENT_TRAINER",
	reset = "RESET",
}

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_TRAINERS](state, payload) {
		state.trainers = payload;
	},

	[MutationTypes.SET_CURRENT_TRAINER](state, payload) {
		state.currentTrainer = payload;
	},

	[MutationTypes.reset](state) {
		state.trainers = [];
		state.currentTrainer = {};
	},
};
