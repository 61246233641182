import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState, store } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import moment, { utc } from "moment";

import { AugmentedActionContext } from "./types";
import { Actions } from "./interfaces";
//
import { ActionTypes as Auth } from "../auth";

// Actions
export enum ActionTypes {
	addToUserSchedule = "ADD_TO_SCHEDULE",
	fetchUserSchedule = "FETCH_FROM_SCHEDULE",
	removeScheduleItem = "REMOVE_FROM_SCHEDULE",
	reset = "RESET",
}

export const actions: ActionTree<State, RootState> & Actions = {
	[ActionTypes.addToUserSchedule](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		const user = payload.userID;
		fb.users
			.doc(user)
			.collection("schedule")
			.doc(payload.classID)
			.set({
				added: true,
				addedAt: firebase.firestore.FieldValue.serverTimestamp(),
				date: new firebase.firestore.Timestamp(
					payload.date.seconds,
					payload.date.nanoseconds
				),
				classID: payload.classID,
				className: payload.className,
			})
			.then(() => {
				// ADD EXPERIENCE POINTS FOR SCHEDULING A CLASS
				store
					.dispatch(Auth.addXP, { name: "ScheduleClass", value: 20 })
					.catch((error) => {
						console.log("Error adding XP!", error.message);
					});
			});
	},

	async [ActionTypes.fetchUserSchedule](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		const user = payload.user;

		const startTime = utc(payload.start).local().format();

		const endTime = utc(payload.end).local().format();

		//actually pull the schedule
		await fb.users
			.doc(user)
			.collection("schedule")
			.where(
				"date",
				">=",
				firebase.firestore.Timestamp.fromDate(new Date(startTime))
			)
			.where(
				"date",
				"<=",
				firebase.firestore.Timestamp.fromDate(new Date(endTime))
			)
			.onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
				const data = querySnapshot.docs;
				const output = [] as firebase.firestore.DocumentData[];
				data.forEach(
					(documentSnapshot: firebase.firestore.DocumentSnapshot) => {
						const docData = documentSnapshot.data();

						if (docData) {
							output.push({
								...docData,
								id: documentSnapshot.id,
								dateMoment: moment(docData.date.seconds * 1000),
							});
						}
					}
				);

				commit(MutationTypes.setUserSchedule, output);
			});
	},

	async [ActionTypes.removeScheduleItem](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		await fb.users
			.doc(payload.userID)
			.collection("schedule")
			.doc(payload.classID)
			.delete();
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
