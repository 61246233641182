import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState, store } from "@/store";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";

import { AugmentedActionContext } from "./types";
import { Actions } from "./interfaces";

export enum ActionTypes {
	fetchTrainers = "FETCH_TRAINERS",
	selectCurrentTrainer = "SELECT_CURRENT_TRAINER",
	reset = "RESET",
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.fetchTrainers](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		const classes = store.getters.getClasses;
		const trainerList = [] as firebase.firestore.DocumentData[];

		classes.forEach((classs: firebase.firestore.DocumentData) => {
			const trainer: firebase.firestore.DocumentData = classs.trainerData;
			const categoryName: string = classs.categoryName;

			const listTrainer = trainerList.filter(
				(train) => train["id"] == trainer.id
			);

			//if we already have this trainer
			if (listTrainer.length > 0) {
				const index = trainerList.indexOf(listTrainer);
				//add the category name if the trainer does not already have it
				if (
					trainerList[index] &&
					!(categoryName in trainerList[index]["categories"])
				) {
					trainerList[index]["categories"].push(categoryName);
				}
			} else {
				trainer["categories"] = categoryName;
				trainerList.push(trainer);
			}
		});

		commit(MutationTypes.SET_TRAINERS, trainerList);
	},

	[ActionTypes.selectCurrentTrainer](
		{ commit }: AugmentedActionContext,
		payload: firebase.firestore.DocumentData
	) {
		commit(MutationTypes.SET_CURRENT_TRAINER, payload);
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
