import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import firebase from "firebase/app";

// GETTERS
export type Getters<S = State> = {
	leaderboardDay(state: S): firebase.firestore.DocumentData[];
	leaderboardWeek(state: S): firebase.firestore.DocumentData[];
	leaderboardMonth(state: S): firebase.firestore.DocumentData[];
	leaderboardAllTime(state: S): firebase.firestore.DocumentData[];
};

// DECLARE GETTERS
export const getters: GetterTree<State, RootState> & Getters = {
	//
	leaderboardDay: (state) => {
		return state.leaderboardDay;
	},
	//
	leaderboardWeek: (state) => {
		return state.leaderboardWeek;
	},
	//
	leaderboardMonth: (state) => {
		return state.leaderboardMonth;
	},
	//
	leaderboardAllTime: (state) => {
		return state.leaderboardAllTime;
	},
};
