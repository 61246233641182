import { MutationTree } from "vuex";
import { State } from "./index";
import { Mutations } from "./types";
import firebase from "firebase/app";

// DEFINE MUTATION TYPES
export enum MutationTypes {
	GET_LEADERBOARD_DAY = "GET_LEADERBOARD_FOR_THIS_DAY",
	GET_LEADERBOARD_WEEK = "GET_LEADERBOARD_FOR_THIS_WEEK",
	GET_LEADERBOARD_MONTH = "GET_LEADERBOARD_FOR_THIS_MONTH",
	GET_LEADERBOARD_ALL_TIME = "GET_LEADERBOARD_ALL_TIME",
}

// IMPLEMENT MUTATIONS
export const mutations: MutationTree<State> & Mutations = {
	// GET LEADERBOARD FOR THIS DAY
	[MutationTypes.GET_LEADERBOARD_DAY](
		state,
		payload: firebase.firestore.DocumentData[]
	) {
		state.leaderboardDay = payload;
	},

	// GET LEADERBOARD FOR THIS WEEK
	[MutationTypes.GET_LEADERBOARD_WEEK](
		state,
		payload: firebase.firestore.DocumentData[]
	) {
		state.leaderboardWeek = payload;
	},

	// GET LEADERBOARD FOR THIS MONTH
	[MutationTypes.GET_LEADERBOARD_MONTH](
		state,
		payload: firebase.firestore.DocumentData[]
	) {
		state.leaderboardMonth = payload;
	},

	// GET LEADERBOARD FOR ALL TIME
	[MutationTypes.GET_LEADERBOARD_ALL_TIME](
		state,
		payload: firebase.firestore.DocumentData[]
	) {
		state.leaderboardAllTime = payload;
	},
};
