import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState, store } from "@/store";

export type Getters<S = State> = {
	isWeb(state: S): any;
};

export const getters: GetterTree<State, RootState> & Getters = {
	isWeb: (state) => {
		return state.isWeb;
	},
};
