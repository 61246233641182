import { MutationTree } from "vuex";
import { State } from "./index";
// (B) - mutations
// [B.1] define mutation types
// ==> { X = "SET_ X" }
export enum MutationTypes {
	SET_PROGRAMS = "SET_PROGRAMS",
	reset = "RESET",
}

// [B.2] declare the mutratione
// ==> [MutationTypes.X](state: S, payload: TIPO_DE_X): void;
export type Mutations<S = State> = {
	[MutationTypes.SET_PROGRAMS](state: S, payload: any): void;
};

// [B.3] implement mutation
// ==>  [MutationTypes.X](state, payload) {
// ==>    state.x = payload;
// ==>  },
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_PROGRAMS](state, payload) {
		// console.log("MUTATING");
		// console.log(payload);
		state.programs = payload;
	},
	[MutationTypes.reset](state) {
		state.programs = [];
	},
};
