<template>
	<ion-content>
		<ion-header
			class="ion-no-border border-bottom"
			style="position: fixed; top: 0"
		>
			<ion-toolbar color="light">
				<ion-title class="text-md">Filter Classes</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<!--  -->
		<ion-list style="margin-top: 70px" color="light">
			<br />
			<ion-item color="medium">
				<ion-label>Workout Type</ion-label>
			</ion-item>
			<!--  -->
			<ion-item v-if="!toggleValues['live']" color="light">
				<ion-label>Live</ion-label>
				<ion-toggle
					color="primary"
					value="live"
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<ion-item v-if="toggleValues['live']" color="light">
				<ion-label>Live</ion-label>
				<ion-toggle
					color="primary"
					value="live"
					checked
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<!--  -->
			<ion-item v-if="!toggleValues['onDemand']" color="light">
				<ion-label>On Demand</ion-label>
				<ion-toggle
					color="primary"
					value="onDemand"
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<ion-item v-if="toggleValues['onDemand']" color="light">
				<ion-label>On Demand</ion-label>
				<ion-toggle
					color="primary"
					value="onDemand"
					checked
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<!--  -->
			<!-- <ion-item v-if="!toggleValues['liveReplay']" color="light">
				<ion-label>Live Replay</ion-label>
				<ion-toggle
					color="primary"
					value="liveReplay"
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item> -->
			<!-- <ion-item v-if="toggleValues['liveReplay']" color="light">
				<ion-label>Live Replay</ion-label>
				<ion-toggle
					color="primary"
					value="liveReplay"
					checked
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item> -->
			<!--  -->
			<ion-item color="medium">
				<ion-label>Workout Length</ion-label>
			</ion-item>
			<!--  -->
			<ion-item v-if="!toggleValues['minute10']" color="light">
				<ion-label>10 Minute</ion-label>
				<ion-toggle
					color="primary"
					value="minute10"
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<ion-item v-if="toggleValues['minute10']" color="light">
				<ion-label>10 Minute</ion-label>
				<ion-toggle
					color="primary"
					value="minute10"
					checked
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<!--  -->
			<ion-item v-if="!toggleValues['minute30']" color="light">
				<ion-label>30 Minute</ion-label>
				<ion-toggle
					color="primary"
					value="minute30"
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<ion-item v-if="toggleValues['minute30']" color="light">
				<ion-label>30 Minute</ion-label>
				<ion-toggle
					color="primary"
					value="minute30"
					checked
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<!--  -->
			<ion-item v-if="!toggleValues['minute45']" color="light">
				<ion-label>45 Minute</ion-label>
				<ion-toggle
					color="primary"
					value="minute45"
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<ion-item v-if="toggleValues['minute45']" color="light">
				<ion-label>45 Minute</ion-label>
				<ion-toggle
					color="primary"
					value="minute45"
					checked
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<!--  -->
			<ion-item v-if="!toggleValues['minute60']" color="light">
				<ion-label>60 Minute</ion-label>
				<ion-toggle
					color="primary"
					value="minute60"
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<ion-item v-if="toggleValues['minute60']" color="light">
				<ion-label>60 Minute</ion-label>
				<ion-toggle
					color="primary"
					value="minute60"
					checked
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<!--  -->
			<ion-item color="medium">
				<ion-label>My Classes</ion-label>
			</ion-item>
			<!--  -->
			<ion-item v-if="!toggleValues['bookmarked']" color="light">
				<ion-label>Only Bookmarked</ion-label>
				<ion-toggle
					color="primary"
					value="bookmarked"
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<ion-item v-if="toggleValues['bookmarked']" color="light">
				<ion-label>Only Bookmarked</ion-label>
				<ion-toggle
					color="primary"
					value="bookmarked"
					checked
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<!--  -->
			<ion-item v-if="!toggleValues['hideWatched']" color="light">
				<ion-label>Hide Watched</ion-label>
				<ion-toggle
					color="primary"
					value="hideWatched"
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<ion-item v-if="toggleValues['hideWatched']" color="light">
				<ion-label>Hide Watched</ion-label>
				<ion-toggle
					color="primary"
					value="hideWatched"
					checked
					@click="toggleValue($event.target.value)"
				></ion-toggle>
			</ion-item>
			<!-- <ion-item color="light">
						<ion-label>Workout Intensity</ion-label>
					</ion-item> -->
			<!--  -->
			<!-- <ion-item color="light">
						<ion-label>Low</ion-label>
						<ion-toggle color="primary"></ion-toggle>
					</ion-item> -->
			<!--  -->
			<!-- <ion-item color="light">
						<ion-label>Moderate</ion-label>
						<ion-toggle color="primary"></ion-toggle>
					</ion-item> -->
			<!--  -->
			<!-- <ion-item color="light">
						<ion-label>High</ion-label>
						<ion-toggle color="primary"></ion-toggle>
					</ion-item> -->
			<!--  -->
			<ion-grid color="light">
				<ion-row>
					<ion-col
						><ion-button color="primary" size="block" @click="classFilter"
							>Find Classes</ion-button
						></ion-col
					>
					<ion-col
						><ion-button
							color="secondary"
							size="block"
							@click="showAllClassesClick()"
							>Show All</ion-button
						></ion-col
					>
				</ion-row>
			</ion-grid>
		</ion-list>
		<!--  -->
	</ion-content>
</template>

<script>
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";

// Import Filter Functions from utils/filters
import {
	filterByDurations,
	filterByLive,
	filterByLiveReplay,
	filterByOnDemand,
	filterByWorkoutType,
} from "../../utils/filters";

export default defineComponent({
	name: "FilterModal",
	// Get classes from the previous view
	props: {
		classes: {},
		setFilter: Function,
		showAllClasses: Function,
		toggleHandler: Function,
		filterBookmarked: Function,
		hideWatched: Function,
		toggleValuesInitial: {},
	},
	// components: {},
	// setup() {},
	data() {
		return {
			// Values from toggle buttons
			toggleValues: { ...this.toggleValuesInitial },

			// Filter Values to be parsed to the filter function
			filterValues: {
				live: "live",
				onDemand: "onDemand",
				liveReplay: "liveReplay",
				minute10: 10,
				minute30: 30,
				minute45: 45,
				minute60: 60,
				bookmarked: "bookmarked",
				hideWatched: "hideWatched",
				// low: "low",
				// moderate: "moderate",
				// high: "high",
			},
		};
	},
	beforeMount() {
		// console.log("Props", this.classes);
	},
	methods: {
		classFilter() {
			const filteredToggleValues = this.filterToggleValues();
			const filteredValues = this.filterSelection(filteredToggleValues);
			//
			// Final Result Object
			let finalResult = [];

			// Make calls to filtering functions

			// Set durations for filterByDurations
			const durations = [];
			if (filteredValues.minute10) durations.push(filteredValues.minute10);
			if (filteredValues.minute30) durations.push(filteredValues.minute30);
			if (filteredValues.minute45) durations.push(filteredValues.minute45);
			if (filteredValues.minute60) durations.push(filteredValues.minute60);
			// Filter Durations
			if (durations.length !== 0) {
				finalResult = filterByDurations(durations, this.classes);
			} else {
				finalResult = this.classes;
			}

			// Filter Bookmarked Classes
			if (filteredValues.bookmarked) {
				finalResult = this.filterBookmarked(finalResult);
				// console.log(finalResult);
			}

			// Hide Watched Videos
			if (filteredValues.hideWatched) {
				finalResult = this.hideWatched(finalResult);
			}

			//Testing
			const videoTypes = {
				onDemand: [],
				live: [],
				liveReplay: [],
			};

			// Filter Live
			if (filteredValues.live) {
				videoTypes.live = filterByLive(finalResult);
			}
			// Filter onDemand
			if (filteredValues.onDemand) {
				videoTypes.onDemand = filterByOnDemand(finalResult);
			}
			// Filter Live Replay
			if (filteredValues.liveReplay) {
				videoTypes.liveReplay = filterByLiveReplay(finalResult);
			}

			//
			if (videoTypes.onDemand.length > 0) {
				finalResult = [...videoTypes.onDemand];
			}
			if (videoTypes.live.length > 0) {
				finalResult = [...videoTypes.live];
			}
			if (videoTypes.liveReplay.length > 0) {
				finalResult = [...videoTypes.liveReplay];
			}

			// finalResult = [
			// 	...videoTypes.onDemand,
			// 	...videoTypes.live,
			// 	...videoTypes.liveReplay,
			// ];
			// Log Final Result
			// console.log("Result", finalResult);

			// Parse final result
			this.setFilter(finalResult);

			// Parse Toggle Buttons States
			this.toggleHandler(this.toggleValues);

			this.close();
		},
		// Filter out toggle values
		filterToggleValues() {
			const filteredToggleValues = { ...this.toggleValues };
			Object.keys(filteredToggleValues).forEach(function (key) {
				if (filteredToggleValues[key] === false) {
					delete filteredToggleValues[key];
				}
			});
			return filteredToggleValues;
		},
		// Filter out unselected filter values
		filterSelection(filteredToggleValues) {
			const filteredValues = { ...this.filterValues };
			Object.keys(filteredValues).forEach(function (key) {
				if (typeof filteredToggleValues[key] === "undefined") {
					delete filteredValues[key];
				}
			});
			return filteredValues;
		},
		// Toggle elements of toggleValues to true/false from template
		toggleValue(index) {
			// console.log(index);
			if (!this.toggleValues[index]) {
				this.toggleValues[index] = true;
				// console.log(this.toggleValues[index]);
			} else {
				this.toggleValues[index] = false;
				// console.log(this.toggleValues[index]);
			}
		},
		// Show All Classes
		showAllClassesClick() {
			// console.log("yoooo");

			// Reset Selections
			const resetSelections = this.toggleValues;
			Object.keys(resetSelections).forEach(function (key) {
				// console.log("Resetting", key);
				resetSelections[key] = false;
			});
			this.toggleValues = resetSelections;
			// Parse Toggle Buttons States
			this.toggleHandler(resetSelections);

			this.showAllClasses();
			this.close();
		},
		// Close Modal
		async close() {
			await modalController.dismiss();
		},
	},
	// computed: {},
	// watch: {},
});
</script>
<style scoped></style>
