
import {
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonCol,
	IonGrid,
	IonCard,
	IonList,
	IonCardTitle,
	IonInfiniteScrollContent,
	IonInfiniteScroll,
	IonCardContent,
	loadingController,
} from "@ionic/vue";

import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { store } from "../store";
import { ActionTypes as Program } from "../store/modules/programs";
import Navbar from "@/components/Navbar.vue";
import Bottombar from "@/components/Bottombar.vue";

export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
		IonCol,
		IonGrid,
		IonCard,
		IonRow,
		IonCardContent,
		IonList,
		IonCardTitle,
		IonInfiniteScrollContent,
		IonInfiniteScroll,
	},
	setup() {
		const router = useRouter();
		// const store = useStore();
		return {
			router,
		};
	},
	methods: {
		openTrainerVideos() {
			this.$router.push({
				path: "/trainervideos",
			});
		},
		pullClasses(id: string, name: string) {
			console.log(id);
			this.$router.push(`/classes/live/${id}`);
		},
	},

	beforeMount: async function () {
		const loading = await loadingController.create({
			message: "Please wait...",
		});
		const programs = await store.getters.programs;
		if (programs.length == 0) {
			await loading.present();
		}

		await store.dispatch(Program.fetchPrograms);
		loading.dismiss();
	},
	computed: {
		programs() {
			const programs = store.getters.programs;
			return programs;
		},
	},
	data() {
		return {
			items: [] as firebase.firestore.DocumentData[],
		};
	},
	watch: {
		//check if programs were updated
		programs(newProgram, oldProgram) {
			console.log(newProgram);
			this.items = [];

			const programs: firebase.firestore.DocumentData[] = [];
			newProgram.forEach(
				async (element: firebase.firestore.DocumentData, index: number) => {
					const validProgram = await store.dispatch(
						Program.checkValidProgram,
						element.classes
					);

					//program is valid so we add it
					if (validProgram) {
						programs.push(element);
					}
				}
			);
			this.items = programs;
			console.log(this.items);
		},

		trainers(newTrainer, oldTrainer) {
			console.log("TRAINERS UPDATED");
			console.log(newTrainer);
		},
	},
});
