export const filterByDuration = (duration: number, classes: any[]) => {
	return classes.filter((classs: any) => {
		return classs.duration === duration;
	});
};

export const filterByDurations = (durations: number[], classes: any[]) => {
	//   console.log(durations)
	return classes.filter((classs: any) => {
		return durations.includes(classs.duration);
	});
};

export const filterByCategory = (categoryName: string, classes: any[]) => {
	return classes.filter(
		(classObject) =>
			classObject.categoryName.toUpperCase() === categoryName.toUpperCase()
	);
};

export const filterByCategories = (categories: any[], classes: any[]) => {
	return classes.filter((classObject) => {
		const categoryNames = [] as string[];

		categories.forEach((category: any) => {
			categoryNames.push(category.name.toUpperCase());
		});

		return categoryNames.includes(classObject.categoryName.toUpperCase());
	});
};

export const filterByIntensity = (intensity: string, classes: any[]) => {
	return classes.filter((classObject) => classObject.intensity === intensity);
};

export const filterByLive = (classes: any[]) => {
	return classes.filter((classObject) => classObject.isLive === true);
};

export const filterByLiveReplay = (classes: any[]) => {
	return classes.filter((classObject) => classObject.isLiveReplay === true);
};

export const filterByOnDemand = (classes: any[]) => {
	return classes.filter((classObject) => classObject.isLive === false);
};

export const filterByWorkoutType = (type: string, classes: any[]) => {
	switch (type) {
		case "On Demand":
			return filterByOnDemand(classes);

		case "Live":
			return filterByLive(classes);

		case "LiveReplay":
			return filterByLiveReplay(classes);
	}
};
