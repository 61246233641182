<template>
	<ion-content
		:color="client?.mode != 'dark' ? 'dark' : ''"
		class="ion-padding text-center"
	>
		<ion-grid>
			<ion-row>
				<ion-col>
					<ion-icon
						:icon="trophyOutline"
						style="font-size: 64px"
						color="success"
					></ion-icon>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					<h2>{{ title }}</h2>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					<ion-icon
						:icon="timerOutline"
						size="large"
						color="primary"
					></ion-icon>
				</ion-col>
				<ion-col>
					<ion-icon
						:icon="flameOutline"
						size="large"
						color="warning"
					></ion-icon>
				</ion-col>
				<ion-col v-if="isMobile && healthAppConnected">
					<ion-icon :icon="heartOutline" size="large" color="danger"></ion-icon>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col
					><ion-label class="text-md"
						><strong class="text-primary">Duration</strong><br />
						{{ parseDuration(duration) }}</ion-label
					></ion-col
				>
				<ion-col
					><ion-label class="text-md"
						><strong class="text-warning">Calories burnt</strong><br />
						{{ calories }}</ion-label
					></ion-col
				>
				<ion-col v-if="isMobile && healthAppConnected"
					><ion-label class="text-md"
						><strong class="text-danger">Heart Rate</strong><br />
						{{ heartRate }} bpm</ion-label
					></ion-col
				>
			</ion-row>
			<ion-row>
				<ion-col> <br /><br /> </ion-col>
			</ion-row>
			<!-- <ion-row>
				<ion-col> Did you enjoy this class? </ion-col>
			</ion-row>
			<ion-row>
				<ion-col size="3" offset="3">
					<ion-button fill="outline" color="medium" size="large" ref="thumbup"
						><ion-icon :icon="thumbsUpOutline"></ion-icon
					></ion-button>
				</ion-col>
				<ion-col size="3">
					<ion-button fill="outline" color="medium" size="large" ref="thumbdown"
						><ion-icon :icon="thumbsDownOutline"></ion-icon
					></ion-button>
				</ion-col>
			</ion-row> -->
			<ion-row class="mobile-only">
				<ion-col> <br /><br /> </ion-col>
			</ion-row>
			<ion-row>
				<ion-col> How would you rate this class?<br /><br /> </ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					<ion-icon
						button
						@click="rating = 1"
						v-bind:color="light"
						:icon="getIcon(1)"
					></ion-icon
					><br />1
				</ion-col>

				<ion-col>
					<ion-icon
						button
						@click="rating = 2"
						v-bind:color="light"
						:icon="getIcon(2)"
					></ion-icon
					><br />2
				</ion-col>
				<ion-col>
					<ion-icon
						button
						@click="rating = 3"
						v-bind:color="light"
						:icon="getIcon(3)"
					></ion-icon
					><br />3
				</ion-col>
				<ion-col>
					<ion-icon
						button
						@click="rating = 4"
						v-bind:color="light"
						:icon="getIcon(4)"
					></ion-icon
					><br />4
				</ion-col>
				<ion-col>
					<ion-icon
						button
						@click="rating = 5"
						v-bind:color="light"
						:icon="getIcon(5)"
					></ion-icon
					><br />5
				</ion-col>
			</ion-row>

			<ion-row v-if="completed == false" class="centerLabel">
				<!-- {{completed}} -->
				<br />
				<ion-label class="text-sm"> Why Did You Leave So Early? </ion-label>
				<!-- </ion-row>
			<ion-row :v-if="!completed"> -->
				<ion-textarea
					v-model="reason"
					class="ratingTextBox"
					aria-placeholder="Tell Us Here"
				>
				</ion-textarea>
			</ion-row>

			<ion-row>
				<ion-col>
					<br />
					<!-- <br /> -->
					<ion-button
						@click="updateRating()"
						:color="client?.mode != 'dark' ? 'light' : 'dark'"
						size="block"
						>Done</ion-button
					>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-content>
</template>

<script>
import {
	IonContent,
	IonButton,
	IonLabel,
	IonTextarea,
	isPlatform
} from "@ionic/vue";
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import {
	star,
	starOutline,
	starHalf,
	thumbsDownOutline,
	thumbsUpOutline,
	trophyOutline,
	timerOutline,
	flameOutline,
	heartOutline,
} from "ionicons/icons";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { store } from "../../store";
import { ActionTypes as Auth } from "../../store/modules/auth";

export default defineComponent({
	name: "Modal",
	props: {
		duration: { type: Number, default: 0 },
		calories: { type: Number, default: 0 },
		heartRate: { type: Number, default: 40 },
		class: { default: "" },
		user: { default: "" },
		title: { default: "" },
		client: { default: "" },
		completed: { type: Boolean, default: null },
	},
	setup() {
		return {
			star,
			starOutline,
			starHalf,
			thumbsDownOutline,
			thumbsUpOutline,
			trophyOutline,
			timerOutline,
			flameOutline,
			heartOutline,
		};
	},
	data() {
		return {
			// content: 'Content',
			rating: 0,
			reason: "",
			isAndroid: false,
			isMobile: false,
			healthAppConnected: false
		};
	},
	components: {
		IonContent,
		IonButton,
		IonLabel,
		IonTextarea,
	},
	mounted() {
		this.healthAppConnected = store.getters.healthAppConnected;
		this.isAndroid = isPlatform("android");
		this.isMobile = isPlatform("ios") || this.isAndroid;
	},
	methods: {
		async close() {
			console.log("closing");
			await modalController.dismiss();
		},

		async updateRating() {
			// console.log("updated rating", this.rating);
			// console.log(this.reason , "reason")
			const rateClass = firebase.functions().httpsCallable("rateClass");

			rateClass({
				rating: this.rating,
				class: this.class,
				reason: this.reason,
			})
				.then((res) => {
					console.log(res, "Rating added");
					// ADD EXPERIENCE POINTS FOR RATING A CLASS
					store
						.dispatch(Auth.addXP, { name: "RateClass", value: 20 })
						.catch((error) => {
							console.log("Error adding XP!", error.message);
						});
				})
				.catch((err) => console.log(err, "error occured in rating"));

			// return result;
			this.$router.back();
			await modalController.dismiss();
		},

		getIcon(ratingValue) {
			return ratingValue === this.rating ? star : starOutline;
		},

		parseDuration(duration) {
			const value = parseInt(duration, 10); // Get value in sec

			let hours = Math.floor(value / 3600); // Get hours
			let minutes = Math.floor((value - hours * 3600) / 60); // Get minutes
			let seconds = value - hours * 3600 - minutes * 60; // Get seconds

			// Add 0 if value < 10 --- (5 => 05)
			if (hours < 10) {
				hours = "0" + hours;
			}
			if (minutes < 10) {
				minutes = "0" + minutes;
			}
			if (seconds < 10) {
				seconds = "0" + seconds;
			}

			// Return format to be displayed
			if (hours == 0) {
				return minutes + " : " + seconds;
			} else {
				return hours + " : " + minutes + " : " + seconds;
			}
		},
	},
});
</script>
<style scoped>
.ratingTextBox {
	/* width: 90vw;
		height: 15vh; */
	border: 1px solid white;
}

.centerLabel {
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	/* padding-top: 2vh; */
}
</style>
