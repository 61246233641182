<template>
	<ion-content class="text-right" v-if="isShowing">
		<ion-item-divider class="text-center">
			<ion-label class="font-sm" color="primary">{{
				userProfile.userName
			}}</ion-label>
		</ion-item-divider>
		<ion-item>
			<ion-button @click="profile()" slot="start" fill="clear" color="medium"
				>My Profile</ion-button
			>
		</ion-item>
		<ion-item>
			<ion-button @click="settings()" slot="start" fill="clear" color="medium"
				>Settings</ion-button
			>
		</ion-item>
		<ion-item>
			<ion-button @click="logout()" slot="start" fill="clear" color="medium"
				>Logout</ion-button
			>
		</ion-item>
	</ion-content>
</template>

<script>
import { IonContent, popoverController, loadingController } from "@ionic/vue";
import { defineComponent } from "vue";
import { store } from "../store";
import { resetStore } from "@/utils/utils";
import { auth } from "@/firebase";
import localStore from "../utils/localstore";
import { ActionTypes as Auth } from "../../src/store/modules/auth";

export default defineComponent({
	name: "UserDropDown",
	components: { IonContent },
	data() {
		return {
			isShowing: true,
		};
	},
	methods: {
		async logout() {

			const loading = await loadingController.create({
				message: "Loading...",
			});
			loading.present();
			await auth.signOut().then(() => {
				resetStore();
				popoverController.dismiss();
				this.$router.push("/");
			});
			loading.dismiss();
		},
		async settings() {
			await popoverController.dismiss();
			this.$router.push("/settings");
		},
		async profile() {
			await popoverController.dismiss();
			this.$router.push("/profile");
		},
	},
	computed: {
		userProfile() {
			const userProfileData = store.getters.userProfile;
			// console.log("Profile View", userProfileData);
			return userProfileData;
		},
	},
});
</script>
