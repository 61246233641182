import { MutationTree } from "vuex";
import { State } from "./index";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";

// Define Mutation Function Names
export enum MutationTypes {
	setPlatform = "SET_PLATFORM",
	setCategories = "SET_CATEGORIES",
	reset = "RESET",
}

// Define Mutation Method Signatures
export type Mutations<S = State> = {
	[MutationTypes.setPlatform](state: S, payload: any): void;
};

// Define Actual Mutations according to method signatures
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.setPlatform](state, payload) {
		state.isWeb = payload;
	},
};
