
import {
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonCol,
	IonGrid,
	IonIcon,
	modalController,
	isPlatform,
	loadingController,
	IonAlert,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import {
	sync,
	lockClosedOutline,
	accessibilityOutline,
	heartOutline,
	watchOutline,
	bluetoothOutline,
	searchOutline,
	openOutline,
	helpBuoyOutline,
	logOutOutline,
} from "ionicons/icons";
import Navbar from "@/components/Navbar.vue";
import Bottombar from "@/components/Bottombar.vue";
import ResetModal from "./modals/modalResetPassword.vue";
import SubscriptionModal from "./modals/modalSubscription.vue";
// import AboutUSModal from "./modals/modalAboutUs.vue";
// import SupportModal from "./modals/modalSupport.vue";
import { store } from "../store";
import { ActionTypes as Auth } from "../store/modules/auth";
import { ActionTypes as HealthActions } from "../store/modules/health";
import modalSearch from "./modals/modalSearch.vue";
import { useRouter } from "vue-router";
import localStore from "../utils/localstore";
import { Health } from "@ionic-native/health";
import { resetStore } from "@/utils/utils";
import { auth } from "@/firebase";

export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
		IonRow,
		IonCol,
		IonGrid,
		IonIcon,
		IonAlert,
	},
	data() {
		return {
			health: Health,
			healthConnected: false,
			height: "",
			weight: "",
			gender: "",
			dob: "",
		};
	},
	setup() {
		const router = useRouter();
		// Alert dialog
		const isOpenRef = ref(false);
		const setOpen = (state: boolean) => (isOpenRef.value = state);
		const buttons = ["Ok"];
		const isAndroid = isPlatform("android");
		const isIOS = isPlatform("ios");

		return {
			sync,
			lockClosedOutline,
			accessibilityOutline,
			heartOutline,
			watchOutline,
			bluetoothOutline,
			searchOutline,
			openOutline,
			helpBuoyOutline,
			logOutOutline,
			router,
			buttons,
			isOpenRef,
			setOpen,
			isAndroid,
			isIOS,
		};
	},
	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
	},
	mounted: async function () {
		this.healthConnected = store.getters.healthAppConnected;
	},
	methods: {
		async findMembers() {
			const modal = await modalController.create({
				component: modalSearch,
			});
			return modal.present();
		},
		async openResetModal() {
			const modal = await modalController.create({
				component: ResetModal,
				cssClass: "modal-custom",
				componentProps: {},
			});
			return modal.present();
		},
		async openSubscriptionModal() {
			const modal = await modalController.create({
				component: SubscriptionModal,
				cssClass: "modal-custom",
				componentProps: {},
			});
			return modal.present();
		},
		async logout() {
			const loading = await loadingController.create({
				message: "Loading...",
			});
			loading.present();
			await auth.signOut().then(() => {
				resetStore();
				this.$router.push("/");
			});
			loading.dismiss();
		},
		async connectHealthApp() {
			const unAuthorized = store.getters.unAuthorized;
			// Connect to health app only if on mobile OS, not already connected and user has not denied permissions
			if (
				!this.healthConnected &&
				(isPlatform("android") || isPlatform("ios")) &&
				!unAuthorized
			) {
				const loading = await loadingController.create({
					message: "Connecting...",
				});
				loading.present();

				await store.dispatch(HealthActions.connectHealthApp);
				await store.dispatch(HealthActions.checkPermissions);
				this.healthConnected = store.getters.healthAppConnected;
				loading.dismiss();
			} else if (unAuthorized) {
				await store.dispatch(HealthActions.checkPermissions);
				this.healthConnected = store.getters.healthAppConnected;
				// Alert user to authorize app
				if (!this.healthConnected) this.setOpen(true);
			}
		},
		async getAllHealthData() {
			const loading = await loadingController.create({
				message: "Getting health data...",
			});
			await loading.present();

			if (isPlatform("android")) {
				await store.dispatch(HealthActions.getHealthData, "height");
				this.height = store.getters.height;
				await store.dispatch(HealthActions.getHealthData, "weight");
				this.weight = store.getters.weight;
				loading.dismiss();
			} else {
				await store.dispatch(HealthActions.getHealthData, "height");
				this.height = store.getters.height;
				await store.dispatch(HealthActions.getHealthData, "weight");
				this.weight = store.getters.weight;
				await store.dispatch(HealthActions.getHealthData, "gender");
				this.gender = store.getters.gender;
				await store.dispatch(HealthActions.getHealthData, "date_of_birth");
				this.dob = store.getters.dob;
				loading.dismiss();
			}
		},
		// Update user profile with health app data
		async updateUserProfile() {
			await this.getAllHealthData();

			const loading = await loadingController.create({
				message: "Updating user profile...",
			});
			await loading.present();

			const isIOS = isPlatform("ios");

			if ((isPlatform("android") || isIOS) && this.healthConnected) {
				const user = await localStore.get("user");
				const uid = user.uid;

				interface PayloadInterface {
					[key: string]: string;
				}
				let payload: PayloadInterface;

				if (isIOS) {
					payload = {
						uid: uid,
						gender: this.gender, // IOS only
						dob: this.dob, // IOS only
						height: this.height,
						weight: this.weight,
					};
				} else {
					payload = {
						uid: uid,
						height: this.height,
						weight: this.weight,
					};
				}

				// Remove empty items from payload
				for (const prop in payload) {
					if (payload[prop] === "") {
						delete payload[prop];
					}
				}

				// Format output for android logcat
				// console.log("FILTERED PAYLOAD", JSON.stringify(payload));

				// Update user profile in firestore
				await store.dispatch(Auth.updateUserProfile, payload);

				// Update the user profile in local storage
				await store.dispatch(Auth.userProfile, uid);
			}
			loading.dismiss();
		},
		// async openSupportModal() {
		//   const modal = await modalController.create({
		//     component: SupportModal,
		//     cssClass: "modal-custom",
		//     componentProps: {},
		//   });
		//   return modal.present();
		// },
		// async openAboutUSModal() {
		//   const modal = await modalController.create({
		//     component: AboutUSModal,
		//     cssClass: "modal-custom",
		//     componentProps: {},
		//   });
		//   return modal.present();
		// },
	},
});
