import { MutationTree } from "vuex";
import { State } from "./interfaces";
import { Mutations } from "./types";

// (B) - mutations
// [B.1] define mutation types
// ==> { X = "SET_ X" }

export enum MutationTypes {
	setUserData = "SET_USER_DATA",
	setCurrentClient = "SET_CLIENT",
	setClientError = "SET_CLIENT_ERROR",
	resetClientError = "RESET_CLIENT_ERROR_RESET",
	userProfile = "GET_USER_PROFILE",
	reset = "RESET",
	getUserXP = "GET_USER_XP",
	setFetchingProfile = "SETTING_USER_PROFILE",
}

// [B.3] implement mutation
// ==>  [MutationTypes.X](state, payload) {
// ==>    state.x = payload;
// ==>  },
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.setUserData](state, payload) {
		state.value = payload;
	},
	[MutationTypes.setCurrentClient](state, payload) {
		// console.log("current client:", payload);
		state.client = payload;
	},
	[MutationTypes.setClientError](state, payload) {
		state.fetchClientError = payload;
	},
	[MutationTypes.resetClientError](state) {
		state.fetchClientError = undefined;
	},
	[MutationTypes.userProfile](state, payload) {
		state.userProfile = payload;
		// console.log("Payload", payload);
	},
	[MutationTypes.reset](state) {
		state.fetchClientError = undefined;
		state.value = undefined;
		state.userProfile = {};
		state.userXP = {};
		state.fetchingProfile = false;
	},
	[MutationTypes.getUserXP](state, payload) {
		state.userXP = payload;
	},
	[MutationTypes.setFetchingProfile](state, payload) {
		state.fetchingProfile = payload;
	},
};
