import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions, ActionTypes } from "./actions";
import { Module } from "vuex";
import { RootState } from "@/store";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { Store } from "./types";
import { State } from "./interfaces";

const state: State = {
	trainers: [],
	currentTrainer: {},
};

//define and export the module
const trainerModule: Module<State, RootState> = {
	state,
	mutations,
	actions,
	getters,
};

export { State, ActionTypes, Store };
export default trainerModule;
