import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { trainers } from "@/firebase";
import { Getters } from "./types";

export const getters: GetterTree<State, RootState> & Getters = {
	trainers: (state) => {
		return state.trainers;
	},

	getTrainer: (state) => async (trainerId: string) => {
		const trainer = state.trainers.filter(
			(trainer) => trainer["id"] == trainerId
		);
		return trainer;
	},

	currentTrainer: (state) => {
		return state.currentTrainer;
	},
};
