// const downloadVideo = (vid_ID: number, dest: string) => {
//   const request = require("request");

//   /* Create an empty file where we can save data */
//   let file = fs.createWriteStream(dest);

//   /* Using Promises so that we can use the ASYNC AWAIT syntax */
//   return new Promise((resolve: any, reject: any) => {
//     let stream = request({
//       /* Here you should specify the exact link to the file you are trying to download */
//       uri: `http://api.dacast.com/v2/vod/${vid_ID}/download?apikey=182176_298605c576fcc58db733`,
//       headers: {
//         Accept:
//           "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
//         "Accept-Encoding": "gzip, deflate, br",
//         "Accept-Language":
//           "en-US,en;q=0.9,fr;q=0.8,ro;q=0.7,ru;q=0.6,la;q=0.5,pt;q=0.4,de;q=0.3",
//         "Cache-Control": "max-age=0",
//         Connection: "keep-alive",
//         "Upgrade-Insecure-Requests": "1",
//         "User-Agent":
//           "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/68.0.3440.106 Safari/537.36",
//       },
//       /* GZIP true for most of the websites now, disable it if you don't need it */
//       gzip: true,
//     })
//       .pipe(file)
//       .on("finish", () => {
//         console.log(`The file is finished downloading.`);
//         resolve();
//       })
//       .on("error", (error: any) => {
//         reject(error);
//       });
//   }).catch((error) => {
//     console.log(`Something happened: ${error}`);
//   });
// };

import { isPlatform } from "@ionic/vue";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { File, FileError } from "@ionic-native/file";
import axios from "axios";

export const saveVideo = async (
	vidID: number,
	fileName: string
): Promise<any> => {
	console.log("saving video", vidID);
	const url = `http://api.dacast.com/v2/vod/${vidID}/download?apikey=182176_298605c576fcc58db733`;
	const response = await fetch(url);
	const data = await response.text();
	await Filesystem.writeFile({
		path: `${File.applicationDirectory}/PSA/Download/${fileName}`,
		data: data,
		directory: Directory.Data,
	});
};
