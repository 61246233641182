import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

function getFirebaseConfig() {
	const firebaseConfig = {
		// see .env file for instructions
		apiKey: process.env.VUE_APP_FIREBASE_API_KEY || "api-key-not-set",
		authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || "env-not-set",
		databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL || "env-not-set",
		projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || "env-not-set",
		storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || "env-not-set",
		messagingSenderId:
			process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || "env-not-set",
		appId: process.env.VUE_APP_FIREBASE_APP_ID || "env-not-set",
		measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID || "env-not-set",
	};

	return firebaseConfig;
}

// Initialize Firebase
firebase.initializeApp(getFirebaseConfig());

const db = firebase.firestore();
const analytics = firebase.analytics();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();

// functions.useEmulator("localhost", 5001);

const categoryCollection = db.collection("categories");
const classCollection = db.collection("classes");
const programs = db.collection("programs");
const trainers = db.collection("trainers");
const clientCollection = db.collection("clients");
const subscriptions = db.collection("subscriptions");
const users = db.collection("users");
const deviceTokens = db.collection("tokens");

export {
	categoryCollection,
	classCollection,
	programs,
	clientCollection,
	subscriptions,
	trainers,
	db,
	auth,
	analytics,
	users,
	storage,
	functions,
	deviceTokens,
};
