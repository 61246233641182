import { store } from "@/store";
import dayjs from "dayjs";
import moment from "moment";
import localStore from "./localstore";

import { ActionTypes as Auth } from "../store/modules/auth";
import { ActionTypes as Content } from "../store/modules/content";
import { ActionTypes as Global } from "../store/modules/global";
import { ActionTypes as Health } from "../store/modules/health";
import { ActionTypes as Programs } from "../store/modules/programs";
import { ActionTypes as Schedule } from "../store/modules/schedule";
import { ActionTypes as Social } from "../store/modules/social";
import { ActionTypes as Trainers } from "../store/modules/trainers";
import { ActionTypes as VideoPlayer } from "../store/modules/video_player";


export const toDateTime = (secs: any) => {
	const t: Date = new Date(1970, 0, 1);
	t.setSeconds(secs);
	return dayjs(t).format("YYYY/MM/DD");
};

export const getBlobfromImg = async (src: any) => {
	const data = await fetch(src);
	return data.blob();
};

export const classSortDesc = (firstClass: any, secondClass: any) => {
	const firstDate = moment(firstClass.date.seconds * 1000);
	const secondDate = moment(secondClass.date.seconds * 1000);
	const firstAfterSec = firstDate.isAfter(secondDate);
	const secAfterFirst = secondDate.isAfter(firstDate);
	if (firstAfterSec) {
		return -1;
	} else if (secAfterFirst) {
		return 1;
	} else {
		return 0;
	}
};

// Reset entire store to initial values, clear local storage and navigate to Home page
export const resetStore = async () => {
	localStore.clear();

	await store.dispatch(Auth.reset);
	await store.dispatch(Content.reset);
	await store.dispatch(Health.reset);
	await store.dispatch(Schedule.reset);
	await store.dispatch(Social.reset);
	await store.dispatch(VideoPlayer.reset);
	await store.dispatch(Trainers.reset);
	// store.dispatch(Global.reset);
	// store.dispatch(Programs.reset);
};