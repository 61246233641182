// Cordova Health Plugin documentation
// https://github.com/dariosalvi78/cordova-plugin-health

import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState } from "@/store";
import { AugmentedActionContext } from "./types";
import { Actions } from "./interfaces";
import { isPlatform } from "@ionic/vue";
import { Health } from "@ionic-native/health";

// Health Data Actions
export enum ActionTypes {
	connectHealthApp = "CONNECT_TO_HEALTH_APP",
	checkPermissions = "CHECK_PERMISSIONS",
	getHealthData = "GET_HEALTH_DATA",
	getHeartRate = "GET_HEART_RATE",
	getCaloriesBurnt = "GET_CALORIES_BURNT",
	// disconnectHealthApp = "DISCONNECT_HEALTH_APP",
	reset = "RESET",
}

export const actions: ActionTree<State, RootState> & Actions = {

	// Connect to Google Fit or Apple Health Kit
	async [ActionTypes.connectHealthApp](
		{ commit }: AugmentedActionContext
	) {
		const isAndroid = isPlatform("android");
		const isIOS = isPlatform("ios");
		let appAvailable = false;

		if (isAndroid || isIOS) {
			await Health.isAvailable()
				.then((available) => {
					console.log("HEALTH APP AVAILABLE", available);
					appAvailable = available;
				})
				.catch((e) => console.log("HEALTH APP AVAILABLE ERROR - " + e));
		}

		let fitInstallRes = "";
		if (isAndroid && appAvailable) {
			await Health.promptInstallFit()
				.then((res) => {
					console.log("PROMPT INSTALL FIT RESPONSE", res);
					fitInstallRes = res;
				})
				.catch((e) => {
					console.log("PROMPT INSTALL FIT ERROR - " + e);
				});
		}

		if (isAndroid && appAvailable && fitInstallRes === "OK") {
			// Options skipped are IOS only
			const androidOptions = [
				"calories",
				"height",
				"weight", //read and write permissions
				{
					read: ["heart_rate"], //read only permissions
				},
			];

			await Health.requestAuthorization(androidOptions)
				.then((res) => {
					console.log("ANDROID PERMISSIONS REQUEST RESPONSE - " + res);
					commit(MutationTypes.setPermissionsGranted, res);
					commit(MutationTypes.setUnAuthorized, false);
					commit(MutationTypes.setHealthAppConnected, res);
				})
				.catch((e) => {
					console.log("ANDROID PERMISSIONS ERROR - " + e);
					const eStr = e.toString();
					if (eStr.indexOf("Permission denied") !== -1)
						commit(MutationTypes.setUnAuthorized, true);
				});
		}

		if (appAvailable && isIOS) {
			const iosOptions = [
				// "appleExerciseTime",
				"calories",
				"height",
				"weight",
				"gender",
				"date_of_birth", //read and write permissions
				{
					read: ["heart_rate"], //read only permission
				},
			];

			await Health.requestAuthorization(iosOptions)
				.then((res) => {
					console.log("HEALTH KIT PERMISSIONS REQUEST RESPONSE - " + res);
					commit(MutationTypes.setPermissionsGranted, res);
					commit(MutationTypes.setUnAuthorized, false);
					commit(MutationTypes.setHealthAppConnected, res);
					if (res === false)
						commit(MutationTypes.setUnAuthorized, res);
				})
				.catch((e) => {
					console.log("HEALTH KIT PERMISSIONS ERROR - " + e);
				});
		}
	},

	// Check if user granted permissions to health data
	async [ActionTypes.checkPermissions]({ commit }: AugmentedActionContext) {
		console.log("CHECK HEALTH DATA PERMISSIONS");

		const isAndroid = isPlatform("android");
		const isIOS = isPlatform("android");

		if (isAndroid || isIOS) {
			// Fields omitted apply to IOS only
			const androidOptions = [
				"calories",
				"height",
				"weight", //read and write permissions
				{
					read: ["heart_rate"], //read only permissions
				},
			];
			const iosOptions = [
				// "appleExerciseTime",
				"calories",
				"height",
				"weight",
				"gender",
				"date_of_birth", //read and write permissions,
			];

			// In iOS, this function will only check authorization status for writable data. Read-only data will always be considered as not authorized. This is an intended behaviour of HealthKit.
			await Health.isAuthorized(isAndroid ? androidOptions : iosOptions)
				.then((res) => {
					console.log("HEALTH DATA AUTHORIZED RESPONSE - ", res);
					commit(MutationTypes.setPermissionsGranted, res);
					commit(MutationTypes.setUnAuthorized, !res);
					commit(MutationTypes.setHealthAppConnected, res);
				})
				.catch((e) => {
					console.log("HEALTH DATA AUTHORIZED ERROR - ", e);
				});
		}
	},

	// Query to get a single health data option
	async [ActionTypes.getHealthData](
		{ commit }: AugmentedActionContext,
		dataType
	) {
		let value = "";

		console.log("GET HEALTH DATA - ", dataType);

		await Health.query({
			startDate: new Date(new Date().getTime() - 10 * 24 * 60 * 60 * 1000), // 10 days ago
			endDate: new Date(), // eg current date time
			dataType,
			limit: 10, // number of results
		})
			.then((res) => {
				console.log(dataType, " DATA - SUCCESS CALLBACK - RESPONSE - ", JSON.stringify(res));

				const lastIndex = res.length - 1;

				if (lastIndex >= 0) {
					// Get the last value in the array
					value = res[lastIndex].value.toString();
					console.log(dataType, " DATA VALUE - ", value);
				}
			})
			.catch((e) => console.log(dataType, " DATA - ERROR CALLBACK - ", e));

		console.log(dataType, ' SIZE ', value.length);

		// Update the respective health data type
		if (value.length > 0) {
			switch (dataType) {
				case "height": {
					console.log('HEIGHT ', value);
					// Convert height to centimetres with 2 decimal places
					const height = (parseFloat(value) * 100).toFixed(2);
					commit(MutationTypes.setUserHeight, parseFloat(height));
					console.log('HEIGHT ', height);
					break;
				}
				case "weight": {
					// console.log('WEIGHT ', value);
					// Set weight to 2 decimal places (kg)
					const weight = parseFloat(value).toFixed(2);
					commit(MutationTypes.setUserWeight, parseFloat(weight));
					console.log('WEIGHT ', weight);
					break;
				}
				case "gender": {
					commit(MutationTypes.setUserGender, value); // IOS Only
					break;
				}
				case "date_of_birth": {
					commit(MutationTypes.setUserDOB, value); // IOS Only
					break;
				}
				default:
					break;
			}
		}
	},

	// Query to get average heart rate over a specified period (bpm)
	async [ActionTypes.getHeartRate](
		{ commit }: AugmentedActionContext,
		payload
	) {
		let avgHeartRate = 0;

		console.log("GET HEART RATE ACTION");
		console.log("START DATE", new Date(new Date().getTime() - payload.startDate));
		console.log("END DATE", payload.endDate);
		console.log("LIMIT", payload.limit);

		await Health.query({
			startDate: new Date(new Date().getTime() - payload.startDate), // eg 10 days ago (10 * 24 * 60 * 60 * 1000)
			endDate: payload.endDate, // eg current date time
			dataType: "heart_rate",
			limit: payload.limit, // eg 10 results
		})
			.then((res) => {
				console.log(
					"HEART RATE DATA - SUCCESS CALLBACK - RESPONSE - ",
					JSON.stringify(res)
				);

				const arraySize = res.length;
				const lastIndex = arraySize - 1;

				if (lastIndex >= 0) {
					// Calculate the average heart rate
					if (lastIndex === 0) {
						// Get the last value in the array
						const value = parseInt(res[lastIndex].value);
						console.log("LAST HEART RATE VALUE - ", value);
						avgHeartRate = value;
					} else {
						let total = 0;
						for (let i = 0; i < arraySize; i++) {
							total = total + parseInt(res[i].value);
						}
						avgHeartRate = total / arraySize;
					}
				}
			})
			.catch((e) => console.log("HEART RATE DATA - ERROR CALLBACK - ", e));

		commit(MutationTypes.setHeartRate, avgHeartRate);
	},

	// Query to get aggregated calories over a specified period (kcal)
	async [ActionTypes.getCaloriesBurnt](
		{ commit }: AugmentedActionContext,
		payload
	) {
		let aggCalories = 0;

		console.log("GET CALORIES BURNT ACTION");
		console.log("START DATE", new Date(new Date().getTime() - payload.startDate));
		console.log("END DATE", payload.endDate);

		await Health.queryAggregated({
			startDate: new Date(new Date().getTime() - payload.startDate), // eg 10 days ago (10 * 24 * 60 * 60 * 1000)
			endDate: payload.endDate, // eg current date time
			dataType: "calories",
		})
			.then((res: any) => {
				console.log(
					"CALORIES BURNT DATA - SUCCESS CALLBACK - RESPONSE - ",
					JSON.stringify(res)
				);
				if (res.value) aggCalories = res.value;
			})
			.catch((e) => console.log("CALORIES BURNT DATA - ERROR CALLBACK - ", e));

		commit(MutationTypes.setCaloriesBurnt, aggCalories);
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
