
import {
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonCol,
	IonGrid,
	IonCard,
	IonLabel,
	loadingController,
	IonSpinner,
} from "@ionic/vue";

import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { store } from "../store";
import { mapGetters } from "vuex";
import { ActionTypes as Trainer } from "../store/modules/trainers";
import { ActionTypes as Content } from "../store/modules/content";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import Navbar from "@/components/Navbar.vue";
import Bottombar from "@/components/Bottombar.vue";
export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
		IonRow,
		IonCol,
		IonGrid,
		IonCard,
		IonLabel,
		IonSpinner,
	},

	data() {
		return {
			trainerLoading: false,
		};
	},

	async beforeMount() {
		this.trainerLoading = true;
		// await store.dispatch(Content.setCategories);
		// await store.dispatch(Content.setClasses);
		// const trainers = await store.getters.trainers;
		// if (!trainers) {
		// 	// await loading.present();
		// }

		await store.dispatch(Trainer.fetchTrainers);
		this.trainerLoading = false;
	},
	computed: {
		trainers() {
			const trainers = store.getters.trainers;
			return trainers;
		},
	},
	watch: {
		trainers(newTrainers, oldTrainers) {
			// console.log(newTrainers);
		},
	},
	setup() {
		const router = useRouter();

		return {
			router,
		};
	},
	methods: {
		openTrainerVideos(trainer: firebase.firestore.DocumentData) {
			// console.log("ABOUT TO DISPATCH TRAINER");
			// console.log(trainer);
			store.dispatch(Trainer.selectCurrentTrainer, trainer);

			this.$router.push({
				path: `/trainervideos/${trainer.id}`,
			});
		},
	},
});
