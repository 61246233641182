
import {
	IonContent,
	IonHeader,
	IonPage,
	IonToolbar,
	IonIcon,
	IonButton,
	IonAvatar,
	IonLabel,
	IonBackButton,
	IonButtons,
	IonTitle,
	IonCard,
	IonCol,
	IonRow,
	IonGrid,
	modalController,
	IonBadge,
	alertController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Navbar from "../components/Navbar.vue";
import Bottombar from "../components/Bottombar.vue";
import { useRouter } from "vue-router";
import { ActionTypes as Trainer } from "../store/modules/trainers";
import { ActionTypes as Content } from "../store/modules/content";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { store } from "../store";
import {
	calendar,
	personCircle,
	playCircleOutline,
	albumsOutline,
	barbellOutline,
	starSharp,
	menuOutline,
	trophyOutline,
	bookmarkOutline,
	bookmark,
	star,
	starOutline,
	starHalf,
} from "ionicons/icons";
import TrainerModal from "./modals/modalTrainerRating.vue";
import Modal from "./modals/modalVideo.vue";
import { ActionTypes as Auth } from "../store/modules/auth";
export default defineComponent({
	name: "Videos",
	components: {
		IonContent,
		Navbar,
		Bottombar,
		IonHeader,
		IonPage,
		IonCard,
		IonCol,
		IonRow,
		IonGrid,
		IonBadge,
		IonToolbar,
		IonBackButton,
		IonButtons,
		IonTitle,
	},
	data() {
		return {
			modalOpen: false,
		};
	},
	setup() {
		// const store = useStore();
		const router = useRouter();
		return {
			playCircleOutline,
			albumsOutline,
			barbellOutline,
			calendar,
			personCircle,
			starSharp,
			trophyOutline,
			menuOutline,
			alertController,
			bookmarkOutline,
			bookmark,
			router,
			star,
			starOutline,
			starHalf,
		};
	},
	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
		trainer() {
			return store.getters.getTrainer;
		},
		client() {
			return store.getters.client;
		},
		trainerVids() {
			console.log();

			return store.getters.getClassesByTrainer(this.$route.params?.trainerId);
		},

		userSchedule() {
			return store.getters.getUserSchedule;
		},

		classProgress() {
			return store.getters.classProgress;
		},
		bookmarkedClasses() {
			return store.getters.getBookmarkedClasses;
		},
	},

	watch: {
		trainer(newTrainer, oldTrainer) {
			console.log(newTrainer);
		},

		trainerVids(newVids, oldVids) {
			console.log(newVids);
		},
	},

	methods: {
		getColor() {
			if (this.client.mode === "dark") {
				return "light";
			}

			return "dark";
		},
		getStyle() {
			if (this.client.mode === "dark") {
				return "background: var(--ion-color-light)";
			}

			return "background: var(--ion-color-dark)";
		},
		removeBookmark(classObject: firebase.firestore.DocumentData) {
			const payload = {
				classID: classObject.classID,
				userID: store.getters.userData.uid,
			};
			// let alert = null;
			store.dispatch(Content.removeClassBookmark, payload);
			//TODO: This is here just in  case the fucntionality
			// .then(async () => {
			// 	alert = await alertController.create({
			// 		header: "Bookmark Removed",
			// 		message: `${classObject.name} has been unbookmarked!`,
			// 		buttons: ["OK"],
			// 	});
			// 	await alert.present();
			// })
			// .catch(async (error) => {
			// 	alert = await alertController.create({
			// 		header: "Error",
			// 		message: error,
			// 		buttons: ["OK"],
			// 	});
			// 	await alert.present();
			// });
		},
		addBookmark(classObject: firebase.firestore.DocumentData) {
			const payload = {
				classID: classObject.classID,
				userID: store.getters.userData.uid,
			};
			// let alert = null;
			store.dispatch(Content.addClassBookmark, payload);
			// .then(async () => {
			// 	alert = await alertController.create({
			// 		header: "Bookmark Added",
			// 		message: `${classObject.name} has been bookmarked!`,
			// 		buttons: ["OK"],
			// 	});
			// 	await alert.present();
			// })
			// .catch(async (error) => {
			// 	alert = await alertController.create({
			// 		header: "Error",
			// 		message: error,
			// 		buttons: ["OK"],
			// 	});
			// 	await alert.present();
			// });
		},

		isBookmarked(classObject: firebase.firestore.DocumentData) {
			const classID = classObject.classID;
			if (
				this.bookmarkedClasses.some(
					(classs: firebase.firestore.DocumentData) =>
						classs.classID === classID
				)
			) {
				return true;
			}

			return false;
		},

		progressType(classID: string) {
			const progress = this.classProgress.filter(
				(classs: firebase.firestore.DocumentData) => classs.classID === classID
			);
			if (progress[0].isFinished) {
				return "Watched";
			}
			return "Watching";
		},
		hasProgressed(classObject: firebase.firestore.DocumentData) {
			const classID = classObject.classID;
			if (
				this.classProgress.some(
					(classs: firebase.firestore.DocumentData) =>
						classs.classID === classID
				)
			) {
				return true;
			}

			return false;
		},

		// Onclick function to add a trainer rating
		async updateTrainerRating() {
			if (this.modalOpen == false) {
				this.modalOpen = true;
				const modal = await modalController.create({
					component: TrainerModal,
					cssClass: "modal-custom",
					componentProps: {
						title: "Rate Trainer",
						trainer: store.getters.currentTrainer.id,
						client: this.client,
					},
				});
				return modal.present();
			}
		},
		createStars() {
			let rating = this.trainer.trainerRating;
			const unrated = Math.floor(5 - rating);
			console.log("rating", rating);
			console.log(unrated);
			const stars = [];
			while (rating >= 1) {
				rating--;
				stars.push(star);
			}
			if (rating != 0) {
				if (rating >= 0.3) {
					stars.push(starHalf);
				} else {
					stars.push(starOutline);
				}
			}

			for (let i = 0; i < unrated; i++) {
				stars.push(starOutline);
			}
			// console.log(stars)
			return stars;
		},

		isRegistered(classID: string) {
			const isScheduled = this.userSchedule.filter(
				(scheduleItem: firebase.firestore.DocumentData) => {
					return scheduleItem.classID === classID;
				}
			);

			if (isScheduled.length === 0) {
				return false;
			}

			return true;
		},
		async openModal(video: any) {
			console.log(video);
			const modal = await modalController.create({
				component: Modal,
				cssClass: "modal-custom",
				componentProps: {
					title: "Join " + video.name,
					content: video.description,
					videoimage: video.imgURL,
					eventdate: video.date,
					eventname: video.name,
					trainer: video.trainerData.name,
					isLive: video.isLive,
					video: video,
					isScheduled: this.isRegistered(video.classID),
				},
			});
			return modal.present();
		},
	},
});
