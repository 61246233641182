<template>
	<ion-content color="light" class="ion-padding">
		<ion-card>
			<ion-card-header>
				<ion-toolbar color="light">
					<ion-title class="text-md">Search By Username</ion-title>
					<ion-buttons slot="end">
						<ion-button @click="close()">X</ion-button>
					</ion-buttons>
				</ion-toolbar>
			</ion-card-header>
			<ion-card-content>
				<div>
					<label>Enter full username:</label>
					<ion-input
						type="text"
						v-model="searchValue"
						:value="searchValue"
						style="border: 1px solid gray"
					></ion-input>
					<br />
					<ion-button @click="searchUser()">Search</ion-button>
				</div>
			</ion-card-content>
			<ion-card-content>
				<div v-if="searchResult != undefined">
					<h2>Results</h2>
					<br />
					<ion-grid>
						<ion-row>
							<ion-col>
								<ion-item color="light">
									<ion-avatar slot="start">
										<img
											:src="searchResult.avatar"
											v-if="searchResult.avatar && searchResult.avatar !== ''"
										/>
										<img v-else src="/assets/noavatar.png" />
									</ion-avatar>
									<ion-label
										><h2>{{ searchResult.userName }}</h2>
										<h5 class="text-medium">
											{{ searchResult.location }}
										</h5></ion-label
									>
									<div v-if="!searchResult.isSelf">
										<ion-button
											slot="end"
											@click="followUser()"
											v-if="
												!userProfile.following ||
												(!userProfile.following.includes(searchResult.id) &&
													!followLoading)
											"
											>Follow</ion-button
										>
										<ion-button disabled slot="end" v-if="followLoading">
											<ion-spinner name="dots"></ion-spinner>
										</ion-button>
										<ion-button
											slot="end"
											disabled
											v-if="
												userProfile.following &&
												userProfile.following.includes(searchResult.id) &&
												!followLoading
											"
											>Following</ion-button
										>
									</div>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>
				<h3 v-if="noResult">
					There is no username that matches with your search.
				</h3>
			</ion-card-content>
		</ion-card>
	</ion-content>
</template>

<script>
import {
	IonContent,
	IonCard,
	loadingController,
	alertController,
	IonButton,
	IonAvatar,
	IonSpinner,
	IonTitle,
	IonButtons,
	IonToolbar,
	IonCardHeader,
	IonCardContent,
	IonInput,
	IonRow,
	IonGrid,
} from "@ionic/vue";

import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { store } from "../../store";
import * as fb from "../../firebase";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { ActionTypes as Social } from "../../store/modules/social";

export default defineComponent({
	name: "ModalSearch",
	components: {
		IonContent,
		IonButton,
		IonAvatar,
		IonSpinner,
		IonTitle,
		IonButtons,
		IonToolbar,
		IonCardHeader,
		IonCardContent,
		IonInput,
		IonRow,
		IonGrid,
		IonCard,
	},
	setup() {
		return {
			IonCard,
		};
	},
	data() {
		return {
			searchValue: "",
			searchResult: undefined,
			noResult: false,
			followLoading: false,
			foundSelf: false,
		};
	},

	computed: {
		userProfile() {
			// console.log(store.getters.userProfile);
			const userProfileData = store.getters.userProfile;
			return userProfileData;
		},
	},

	watch: {
		userProfile(newProfile, oldProfile) {
			// this.searchUser();
			this.followLoading = false;
		},
	},

	methods: {
		followUser() {
			const payload = {
				uid: store.getters.userData.uid,
				followRef: this.searchResult.id,
			};
			this.followLoading = true;
			store.dispatch(Social.followUser, payload);
		},

		async searchUser() {
			const searchValue = this.searchValue.toLowerCase();
			// console.log("Search Value", searchValue);

			// Reset search result
			this.searchResult = undefined;

			const loading = await loadingController.create({
				cssClass: "alert-wrapper",
				message: "Please wait...",
			});
			await loading.present();

			const alert = await alertController.create({
				header: "Search Error",
				buttons: ["OK"],
			});

			if (searchValue !== "") {
				// Perform search query
				const userSearchSnapShot = await fb.users
					.where("userName", ">=", searchValue)
					.where("userName", "<=", searchValue + "z")
					.get()
					.then((snapshot) => {
						if (snapshot.docs.length == 0) {
							this.noResult = true;
							loading.dismiss();
						} else {
							this.noResult = false;
							// console.log("Result", snapshot.docs);
							loading.dismiss();
							let isFollower = false;
							let isFollowing = false;
							snapshot.docs.forEach((doc) => {
								this.foundSelf = false;
								if (
									store.getters.userProfile.followers &&
									store.getters.userProfile.followers.includes(doc.id)
								) {
									isFollower = true;
								}
								if (
									store.getters.userProfile.following &&
									store.getters.userProfile.following.includes(doc.id)
								) {
									isFollowing = true;
								}
								this.searchResult = {
									...doc.data(),
									id: doc.id,
									isFollower: isFollower,
									isFollowing: isFollowing,
									isSelf: doc.id === store.getters.userProfile.uid,
								};

								// console.log("Results", this.searchResult);
							});
						}
						return snapshot.docs;
					})
					.catch(async (error) => {
						loading.dismiss();

						alert.cssClass = "my-alert-class";
						alert.message = error.message;
						alert.present();

						console.log(error);
					});
				// console.log("User Search", userSearchSnapShot);
			} else {
				loading.dismiss();

				alert.message = "Please enter a search keyword.";
				alert.present();
			}
		},
		async close() {
			// console.log("closing");
			await modalController.dismiss();
		},
	},
});
</script>
