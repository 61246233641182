<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div
				class="video-logo"
				v-bind:style="{
					'background-image': 'url(' + client.logo + ')',
				}"
			></div>
			<div id="test"></div>

			<div class="video-player">
				<ion-grid>
					<ion-row class="row-border-dark" style="margin-bottom: 10px">
						<ion-col size="12" size-lg="10" size-md="12" size-sm="12">
							<div
								class="video-player-embed"
								v-bind:style="[
									video?.mirror && !isWeb ? { height: '88vh !important' } : {},
								]"
								id="embed"
								ref="embed"
							>
								<div
									id="videoPlayer"
									v-bind:class="[isActive ? 'ShowMe' : 'HideMe']"
								></div>
								<!-- Hack to hide the grey video icon for android -->
								<div
									v-if="isAndroid"
									:class="[posterHidden ? 'HideMe' : 'poster']"
									v-bind:style="{
										'background-image':
											'url(' + client.favIconLink + ')',
									}"
								></div>

								<!-- <div
									v-bind:class="[!isActive ? 'ShowMe' : 'HideMe']"
									class="VideoLoader"
								>
									<ion-spinner color="light"></ion-spinner>
								</div> -->
								<!-- <vue-vimeo-player ref="player" :video-id="591473460" :player-height="750" @ready="true"/> -->
								<!-- <iframe
									src="https://player.vimeo.com/video/591473460"
									width="100%"
									height="100%"

									frameborder="0"
									webkitallowfullscreen
									mozallowfullscreen
									allowfullscreen
								></iframe> -->
								<!-- <iframe src="//iframe.dacast.com/b/182176/f/1093545" width="100%" height="100%" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe> -->
							</div>
						</ion-col>
						<ion-col
							v-if="isWeb"
							class="web-only"
							size="12"
							size-lg="2"
							size-md="12"
							size-sm="12"
						>
							<ion-list class="text-sm" :style="getStyle()">
								<h3 class="text-center row-border-dark">
									Online Now {{ viewers.length + 1 }}
								</h3>
								<ion-item :color="getColor()">
									<ion-avatar slot="start">
										<img v-if="currentUser.avatar" :src="currentUser.avatar" />
										<img v-else src="/assets/noavatar.jpg" />
									</ion-avatar>
									<ion-label
										><h2>
											{{ currentUser.userName }}
										</h2>
										<h5 class="text-medium">
											{{ currentUser.location }}
										</h5></ion-label
									>
									<ion-avatar slot="end">
										<!-- <img src="/assets/Heart-2s-200px.gif" /> -->
										<img src="/assets/Heart-100s-200px.png" />
									</ion-avatar>
								</ion-item>
								<ion-item
									:color="getColor()"
									v-for="(viewer, index) in viewers"
									:key="index"
								>
									<ion-avatar slot="start">
										<img v-if="viewer.avatar" :src="viewer.avatar" />
										<img v-else src="/assets/noavatar.jpg" />
									</ion-avatar>
									<ion-label
										><h2>{{ viewer.userName }}</h2>
										<h5 class="text-medium">
											{{ viewer.location }}
										</h5></ion-label
									>
									<ion-avatar slot="end">
										<img src="/assets/Heart-100s-200px.png" />
									</ion-avatar>
								</ion-item>
							</ion-list>
						</ion-col>
						<ion-col class="text-center" size="12">
							<ion-icon
								color="primary"
								v-for="(starIcon, index) in createStars()"
								:key="index"
								:icon="starIcon"
							></ion-icon>
							<!-- Rate workout
							<ion-icon color="primary" :icon="star"></ion-icon>
							<ion-icon color="primary" :icon="star"></ion-icon>
							<ion-icon color="primary" :icon="starHalf"></ion-icon>
							<ion-icon color="light" :icon="starOutline"></ion-icon>
							<ion-icon color="light" :icon="starOutline"></ion-icon> -->
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col
							v-bind:style="[
								video?.mirror && !isWeb ? { display: 'none !important' } : {},
							]"
							size="3"
							size-lg="1"
						>
							<ion-avatar>
								<img :src="video?.trainerData.imgURL" />
							</ion-avatar>
						</ion-col>
						<ion-col
							v-bind:style="[
								video?.mirror && !isWeb ? { display: 'none !important' } : {},
							]"
							size="9"
							size-lg="9"
							size-sm="9"
						>
							<h4>{{ video?.name }}</h4>
							<span class="text-sm">{{ video?.trainerData.name }}</span>
						</ion-col>
						<ion-col size="12" size-lg="2" size-sm="12">
							<ion-button
								:color="getColor()"
								:size="video?.mirror && !isWeb ? 'small' : 'block'"
								@click="getUserWatchStatus()"
								v-bind:style="[
									video?.mirror && !isWeb ? { width: '100% !important' } : {},
								]"
								>End Class</ion-button
							>
							<!-- <ion-button size="block" @click="onComplete()">
								Get statistics
							</ion-button> -->
						</ion-col>
						<ion-col
							v-if="!isWeb && !video?.mirror"
							class="mobile-only"
							size="12"
							size-lg="12"
							size-md="12"
							size-sm="12"
						>
							<ion-list class="text-sm" :style="getStyle()">
								<h3 class="text-center row-border-dark">
									Online Now {{ viewers.length + 1 }}
								</h3>
								<ion-item :color="getColor()">
									<ion-avatar slot="start">
										<img v-if="currentUser.avatar" :src="currentUser.avatar" />
										<img v-else src="/assets/noavatar.jpg" />
									</ion-avatar>
									<ion-label
										><h2>
											{{ currentUser.userName }}
										</h2>
										<h5 class="text-medium">
											{{ currentUser.location }}
										</h5></ion-label
									>
									<ion-avatar slot="end">
										<!-- <img src="/assets/Heart-2s-200px.gif" /> -->
										<img src="/assets/Heart-100s-200px.png" />
									</ion-avatar>
								</ion-item>
								<ion-item
									:color="getColor()"
									v-for="(viewer, index) in viewers"
									:key="index"
								>
									<ion-avatar slot="start">
										<img v-if="viewer.avatar" :src="viewer.avatar" />
										<img v-else src="/assets/noavatar.jpg" />
									</ion-avatar>
									<ion-label
										><h2>{{ viewer.userName }}</h2>
										<h5 class="text-medium">
											{{ viewer.location }}
										</h5></ion-label
									>
									<ion-avatar slot="end">
										<img src="/assets/Heart-100s-200px.png" />
									</ion-avatar>
								</ion-item>
							</ion-list>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>
		</ion-content>
	</ion-page>
</template>
<script>
import {
	IonContent,
	IonPage,
	IonGrid,
	IonRow,
	IonCol,
	modalController,
	IonSpinner,
	IonAvatar,
	IonLabel,
	IonItem,
	IonList,
	IonButton,
	IonImg,
	IonBadge,
	isPlatform,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import useStore, { store } from "../store";
import Modal from "./modals/modalStatistics.vue";
import { ActionTypes as Video } from "../store/modules/video_player";
// import { ActionTypes as Auth } from "../store/modules/auth";
import { star, starOutline, starHalf } from "ionicons/icons";
import { ActionTypes as Content } from "../store/modules/content";
import { Storage } from "@ionic/storage";
import Player from "@vimeo/player";
import { ActionTypes as HealthActions } from "../store/modules/health";
// import RecommendedVue from "./Recommended.vue";
// import { mapGetters } from "vuex";
//
import { ActionTypes as Auth } from "../store/modules/auth";
export default defineComponent({
	name: "VideoPlayer",
	components: {
		IonContent,
		IonAvatar,
		IonLabel,
		IonItem,
		IonList,
		IonButton,
		IonPage,
		IonGrid,
		IonRow,
		IonCol,
	},
	data() {
		return {
			item: {},
			isActive: false,
			//myPlayer: undefined,
			userID: undefined,
			complete: false,
			watching: true,
			playing: false,
			compkey: "",
			playerAPI: undefined,
			playerElement: undefined,
			listener: undefined,
			startDate: undefined,
			endDate: undefined,
			modalOpen: false,
			posterHidden: false,
			healthAppConnected: false,
			isMobile: false,
			isAndroid: false
		};
	},
	setup() {
		const router = useRouter();
		return {
			router,
			star,
			starOutline,
			starHalf,
		};
	},
	async ionViewDidEnter() {
		const localStore = new Storage();
		localStore.create();
		this.userID = store.getters.userData.uid;
		const payload = {
			classID: store.getters.currentVideo?.classID,
			userID: store.getters.userData.uid,
		};
		store.dispatch(Video.updateVideoWatchMilestone, {
			class: store.getters.currentVideo,
			userID: this.userID,
		});
		store.dispatch(Video.addUserWatchedVideo, {
			...payload,
			video: this.video,
		});
		store.dispatch(Video.addUserClassProgress, {
			...payload,
			currentlyViewing: true,
		});
		// ADD EXPERIENCE POINTS FOR WATCHING A CLASS
		store
			.dispatch(Auth.addXP, { name: "WatchClass", value: 10 })
			//TODO: uncomment this at a later stage
			// .then(async () => {
			// 			const toast = await toastController.create({
			// 			header: "Achievement Unlocked ",
			// 			message: "Started A Class \n 10 XP",
			// 			position: "top",
			// 			duration: 2000,
			// 			color: "dark"
			// 		});
			// 		return toast.present();
			// })
			.catch((error) => {
				console.log("Error adding XP!", error.message);
			});
	},
	methods: {
		getColor() {
			if (this.client.mode === "dark") {
				return "light";
			}

			return "dark";
		},
		getStyle() {
			if (this.client.mode === "dark") {
				return "background: var(--ion-color-light)";
			}

			return "background: var(--ion-color-dark)";
		},
		async getUserWatchStatus() {
			this.onPause();

			this.endDate = new Date();

			const timeRemaining =
				(await this.myPlayer.getDuration()) / 60 -
				(await this.myPlayer.getCurrentTime()) / 60;

			// If time remaining less than or equal to 1 min => return onComplete()
			if (timeRemaining <= 1) {
				return this.onComplete();
			}

			// Get video duration
			const videoDuration = this.video.duration;

			// Get watched duration
			const duration = await this.myPlayer.getCurrentTime();

			// MET (metabolic equivalent for task)
			const MET = 6;

			let calories = "";

			let caloriesBurnt = 0;
			// Total calories burned = Duration (in minutes)*(MET*3.5*weight in kg)/200

			let heartRate = 0;

			if (this.isMobile && this.healthAppConnected) {
				// Heart rate
				heartRate = await this.getHeartRate();
				// Get calories burnt from Health app
				caloriesBurnt = await this.getCaloriesBurnt();
				calories = `±  ${caloriesBurnt.toFixed(3)}  kcal`;
			} else {
				// Calories Calculation
				if (!(this.currentUser.weight === "") && this.currentUser.weight > 0) {
					const weight = this.currentUser.weight;
					caloriesBurnt = ((duration / 60) * (MET * 3.5 * weight)) / 200;
					calories = `±  ${caloriesBurnt.toFixed(3)}  cal`;
				} else {
					calories = "Please update your weight in the profile settings.";
				}
			}

			store.dispatch(Video.fetchWatchHistory, { userID: this.userID });
			store.dispatch(Content.fetchBookmarkedClasses, { userID: this.userID });
			if (!this.complete) {
				const modal = await modalController.create({
					component: Modal,
					cssClass: "modal-custom",
					componentProps: {
						title: timeRemaining > 1 ? "Leaving Aleady?" : "Workout Completed",
						status: "incomplete",
						duration,
						calories,
						heartRate,
						client: this.client,
						// class: store.getters.userData.uid,
						class: this.video.classID,
						completed: timeRemaining <= 1,
					},
				});
				return modal.present();
			}
		},
		onPlay() {
			// console.log("Playing");
			this.startDate = new Date().getTime();

			this.complete = false;
			this.playing = true;
			store.dispatch(Video.setUserCurrentlyWatching, {
				userID: this.userID,
				classID: this.video.classID,
				currentlyViewing: true,
			});

			if (this.isAndroid) setTimeout(this.setPosterHidden, 750);
		},
		setPosterHidden() {
			this.posterHidden = true;
		},
		async onPause() {
			console.log("Pausing");
			this.playing = false;

			if (this.myPlayer) {
				this.myPlayer
					.pause()
					.then(() => {
						console.log("PAUSED");
					})
					.catch((err) => {
						console.log("ERROR", err);
					});
				// Check if complete = true => Skip
				if (this.complete) {
					return;
				} else {
					store.dispatch(Video.setPausedTime, {
						classID: store.getters.currentVideo?.classID,
						userID: store.getters.userData.uid,
						currentTime: await this.myPlayer.getCurrentTime(),
						currentlyViewing: true,
						isFinished:
							(await this.myPlayer.getDuration()) -
								(await this.myPlayer.getCurrentTime()) <=
							0
								? true
								: false,
					});
				}
			}
		},
		cleanup() {
			this.listener();
			const obj = document.getElementById("videoPlayer");
			if (obj) {
				obj.remove();
				delete this.playerElement;
				this.myPlayer.destroy();
			}
		},
		createElements() {
			const embed = this.$refs.embed;
			// console.log('VIDEO ELEMENT', JSON.stringify(this.video));
			this.myPlayer = new Player(embed, {
				url: this.video.video.url,
				responsive: true,
				autoplay: true,
				controls: true,
				playbar: true,
			});
			this.myPlayer.on("play", this.onPlay);
			this.myPlayer.on("pause", this.onPause);
			this.myPlayer.on("ended", this.onComplete);
			this.myPlayer.on("ready", this.onReady);
			this.myPlayer.play()
		},
		async onComplete() {
			// console.log("completed");
			this.complete = true;

			this.endDate = new Date();

			// Get video duration
			const videoDuration = this.video.duration;

			// Get watched duration
			const duration = await this.myPlayer.getCurrentTime();

			// MET (metabolic equivalent for task)
			const MET = 6;

			let calories = 0;
			let caloriesBurnt = 0;
			let heartRate = 0;

			if (this.isMobile && this.healthAppConnected) {
				// Heart rate
				heartRate = await this.getHeartRate();
				// Get calories burnt from Health app
				caloriesBurnt = await this.getCaloriesBurnt();
				calories = `±  ${caloriesBurnt.toFixed(3)}  kcal`;
			} else {
				// Calories Calculation
				if (!(this.currentUser.weight === "") && this.currentUser.weight > 0) {
					const weight = this.currentUser.weight;
					caloriesBurnt = ((duration / 60) * (MET * 3.5 * weight)) / 200;
					calories = `±  ${caloriesBurnt.toFixed(3)}  cal`;
				} else {
					calories = "Please update your weight in the profile settings.";
				}
			}

			const timeRemaining =
				(await this.myPlayer?.getDuration()) -
				(await this.myPlayer?.getCurrentTime());

			store
				.dispatch(Video.setVideoComplete, {
					classID: store.getters.currentVideo?.classID,
					userID: store.getters.userData.uid,
					currentlyViewing: false,
					currentTime: await this.myPlayer?.getDuration(),
					isFinished: timeRemaining <= 60 ? true : false,
					duration,
					calories: caloriesBurnt,
					heartRate,
				})
				.then(async () => {
					// ADD EXPERIENCE POINTS FOR FINISHING A CLASS
					store
						.dispatch(Auth.addXP, { name: "FinishClass", value: 50 })
						.catch((error) => {
							console.log("Error adding XP!", error.message);
						});
					// UPDATE VIDEO DAILY WATCH MILESTONE
					store.dispatch(Video.updateDailyWatchMilestone, {
						class: store.getters.currentVideo,
						userID: this.userID,
					});
					// OPEN STATISTICS MODAL
					if (this.modalOpen == false) {
						this.modalOpen = true;
						const modal = await modalController.create({
							component: Modal,
							cssClass: "modal-custom",
							componentProps: {
								title: "Workout Completed",
								duration,
								calories,
								heartRate,
								client: this.client,
								//class: store.getters.userData.uid,
								class: this.video.classID,
								completed: true,
							},
						});
						return modal.present();
					}
				});
		},
		onReady() {
			this.isActive = true;
			// this.myPlayer.volume(1);
			this.myPlayer.setVolume(100);
			// console.log("setting volume")
		},
		createStars() {
			let rating = this.video.videoRating;
			const unrated = Math.floor(5 - rating);
			console.log("rating", rating);
			console.log(unrated);
			const stars = [];
			while (rating >= 1) {
				rating--;
				stars.push(star);
			}
			if (rating != 0) {
				if (rating >= 0.3) {
					stars.push(starHalf);
				} else {
					stars.push(starOutline);
				}
			}

			for (let i = 0; i < unrated; i++) {
				stars.push(starOutline);
			}
			// console.log(stars)
			return stars;
		},
		async getHeartRate() {
			let heartRate = 0;
			if (this.isMobile) {
				const duration = this.endDate.getTime() - this.startDate;
				const payload = {
					startDate: duration,
					endDate: this.endDate,
					limit: 10,
				};
				await store.dispatch(HealthActions.getHeartRate, payload);
				heartRate = store.getters.heartRate;
				// console.log("HEART RATE", heartRate);
			}
			return heartRate;
		},
		async getCaloriesBurnt() {
			let calories = 0;
			if (this.isMobile) {
				const duration = this.endDate.getTime() - this.startDate;
				const payload = {
					startDate: duration,
					endDate: this.endDate,
					limit: 10,
				};
				await store.dispatch(HealthActions.getCaloriesBurnt, payload);
				calories = store.getters.caloriesBurnt;
				// console.log("CALORIES", calories);
			}
			return calories;
		},
	},
	mounted() {
		this.createElements();
		this.healthAppConnected = store.getters.healthAppConnected;
		this.isAndroid = isPlatform("android");
		this.isMobile = isPlatform("ios") || this.isAndroid;
	},
	computed: {
		video() {
			const video = store.getters.currentVideo;
			return video;
		},
		videoRecord() {
			const record = store.getters.record;
			return record;
		},
		client() {
			return store.getters.client;
		},
		viewers() {
			return store.getters.videoViewers;
		},
		currentUser() {
			return store.getters.userProfile;
		},
		isWeb() {
			return store.getters.isWeb;
		},
	},
	watch: {
		video(newVideo, oldVideo) {
			// console.log(newVideo);
		},
		videoRecord(newRecord, oldRecord) {
			// console.log(newRecord);
		},
		viewers(newViewers, oldViewers) {
			// console.log(newViewers);
		},
	},
	ionViewWillLeave() {
		if (this.playing || !this.complete) {
			this.watching = false;
			this.onPause();
			this.myPlayer.destroy();
		}
		store.dispatch(Video.setUserCurrentlyWatching, {
			userID: this.userID,
			classID: this.video.classID,
			currentlyViewing: false,
		});
		this.cleanup();
		store.dispatch(Video.clearWatchList);
	},
	unmounted() {
		this.cleanup();
	},
	async ionViewWillEnter() {
		this.createElements();
		this.listener = await store.dispatch(Video.fetchCurrentViewingUsers, {
			classID: store.getters.currentVideo?.classID,
			userID: store.getters.userData.uid,
		});
		// console.log("LISTENRT", this.listener)
	},
});
</script>
<style scoped>
.HideMe {
	display: none;
}
.ShowMe {
	display: block;
}
.video-player {
	background: #000;
	height: 100vh;
	color: #fff;
}
.video-close-btn {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 10;
}
.poster {
	background: center center;
	background-repeat: no-repeat;
	background-size: 20%;
	background-color: #000;
	bottom: 0;
	left: 0;
	opacity: 1;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	animation: animatedBackground 1s linear infinite alternate;
}
@keyframes animatedBackground {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}
</style>
