
import {
	IonContent,
	IonHeader,
	IonPage,
	IonToolbar,
	IonCheckbox,
	loadingController,
	alertController,
	IonImg,
	IonLabel,
	IonInput,
	IonButton,
	IonCard,
	IonCol,
	IonRow,
	IonGrid,
	modalController,
  isPlatform,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import { store } from "../store";
import { ActionTypes as Auth } from "../store/modules/auth";
import { defineComponent } from "vue";
import localStore from "../utils/localstore";
import TermsModal from "./modals/modalTerms.vue";
// import { Storage } from "@ionic/storage";
import Navbar from "@/components/Navbar.vue";

export default defineComponent({
	name: "Join",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		IonImg,
		IonLabel,
		IonInput,
		IonCheckbox,
		IonButton,
		IonCard,
		IonCol,
		IonRow,
		IonGrid,
	},
	data() {
		return {
			email: "",
			password: "",
			name: "",
			readme: false,
			itemData: {},
		};
	},

	methods: {
		async showTerms() {
			const modal = await modalController.create({
				component: TermsModal,
				// componentProps: {
				// 	classes: this.content.classes,
				// 	setFilter: this.setFilter,
				// 	showAllClasses: this.showAllClasses,
				// 	toggleHandler: this.toggleHandler,
				// 	toggleValuesInitial: this.toggleValues,
				// },
			});
			return modal.present();
		},
		async register() {
			const payload = {
				email: this.email,
				password: this.password,
				name: this.name,
				store: localStore,
			};
			if (this.readme == false) {
				return;
			}
			const loading = await loadingController.create({
				message: "Please wait...",
			});
			await loading.present();

			store
				.dispatch(Auth.signupUser, payload)
				.then(() => {
					loading.dismiss();
					this.$router.push("/start");
				})
				.catch(async (error: { message: any }) => {
					loading.dismiss();
					const alert = await alertController.create({
						header: "Signup Failed",
						message: error.message,
						buttons: ["OK"],
					});
					alert.present();
				});
		},
		async socialAuth(provider: string) {
			const loading = await loadingController.create({
				message: "Please wait...",
			});
			await loading.present();

			const payload = {
				provider,
				store: localStore,
				type: "signup",
			};

			store
				.dispatch(Auth.socialAuth, payload)
				.then(() => {
					loading.dismiss();
					this.$router.push("/start");
				})
				.catch(async (error: { message: any }) => {
					loading.dismiss();
					const alert = await alertController.create({
						header: "Signup Failed",
						message: error.message,
						buttons: ["OK"],
					});
					alert.present();
				});
		},
	},
	computed: {
		client() {
			return store.getters.client;
		},
	},
	setup() {
		let isWeb = false;
		if (isPlatform("mobile") || isPlatform("mobileweb")) {
			isWeb = false;
		} else {
			isWeb = true;
		}
		const router = useRouter();
		return { router, isWeb };
	},
});
