import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState, store } from "@/store";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { Getters } from "./types";

export const getters: GetterTree<State, RootState> & Getters = {
	getUserSchedule: (state) => {
		return state.userSchedule;
	},
};
