import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Classes from "../views/Classes.vue";
import Coaches from "../views/Coaches.vue";
import Programs from "../views/Programs.vue";
import Featured from "../views/Featured.vue";
import Schedule from "../views/Schedule.vue";
import Videos from "../views/Videos.vue";
import TrainerVideos from "../views/TrainerVideos.vue";
import VideoPlayer from "../views/VideoPlayer.vue";
import Landing from "../views/Landing.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import Join from "../views/Join.vue";
import IAP from "../views/IAP.vue";
import Onboarding from "../views/Onboarding.vue";
import Profile from "../views/Profile.vue";
import Subscribe from "../views/Subscribe.vue";
import Settings from "../views/Settings.vue";
import Recommended from "../views/Recommended.vue";
import Leaderboard from "../views/Leaderboard.vue";
import Wearable from "../views/Wearable.vue";
import Login from "../views/Login.vue";
import ResetLogin from "../views/ResetLogin.vue";
import { store } from "../store";

const authMiddleware = async (to: any, from: any, next: any) => {
	const auth = await store.getters.auth;
	!auth ? next("/login") : next();
};

const authedMiddleware = async (to: any, from: any, next: any) => {
	const auth = await store.getters.auth;
	auth ? next("/classes") : next();
};

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Landing",
		component: Landing,
		beforeEnter: authedMiddleware,
	},
	{
		path: "/classes",
		name: "Classes",
		component: Classes,
	},
	{
		path: "/coaches",
		name: "Coaches",
		component: Coaches,
	},
	{
		path: "/programs",
		name: "Programs",
		component: Programs,
	},
	{
		path: "/featured",
		name: "Featured",
		component: Featured,
	},
	{
		path: "/schedule",
		name: "Schedule",
		component: Schedule,
		beforeEnter: authMiddleware,
	},
	{
		path: "/videoplayer/:contentID",
		name: "VideoPlayer",
		component: VideoPlayer,
		beforeEnter: authMiddleware,
	},
	{
		path: "/trainervideos/:trainerId",
		name: "TrainerVideos",
		component: TrainerVideos,
	},
	{
		path: "/videos/:categoryName",
		name: "Videos",
		component: Videos,
	},
	{
		path: "/about",
		name: "About",
		component: About,
	},
	{
		path: "/contact",
		name: "Contact",
		component: Contact,
	},
	{
		path: "/subscribe",
		name: "Subscribe",
		component: Subscribe,
		beforeEnter: authMiddleware,
	},
	{
		path: "/start",
		name: "Onboarding",
		component: Onboarding,
		beforeEnter: authMiddleware,
	},
	{
		path: "/profile",
		name: "Profile",
		component: Profile,
		beforeEnter: authMiddleware,
	},
	{
		path: "/settings",
		name: "Settings",
		component: Settings,
		beforeEnter: authMiddleware,
	},
	{
		path: "/wearable",
		name: "Wearable",
		component: Wearable,
		beforeEnter: authMiddleware,
	},
	{
		path: "/leaderboard",
		name: "Leaderboard",
		component: Leaderboard,
		beforeEnter: authMiddleware,
	},
	{
		path: "/recommended",
		name: "Recommended",
		component: Recommended,
		beforeEnter: authMiddleware,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
		beforeEnter: authedMiddleware,
	},
	{
		path: "/resetlogin",
		name: "ResetLogin",
		component: ResetLogin,
		beforeEnter: authedMiddleware,
	},
	{
		path: "/join",
		name: "Join",
		component: Join,
		beforeEnter: authedMiddleware,
	},
	{
		path: "/iap",
		name: "IAP",
		component: IAP,
		beforeEnter: authMiddleware,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
