
import {
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonCol,
	IonGrid,
	IonCard,
	IonLabel,
	IonItem,
	IonCheckbox,
	IonText,
	IonImg,
	IonButton,
	modalController,
	IonSpinner,
	alertController,
	IonIcon,
	IonFab,
	IonBadge,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { store } from "../store";
import Bottombar from "../components/Bottombar.vue";
import Modal from "./modals/modalVideo.vue";
import FilterModal from "./modals/modalFilter.vue";
import Navbar from "../components/Navbar.vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { ActionTypes as Auth } from "../store/modules/auth";
import { ActionTypes as Content } from "../store/modules/content";
import {
	bookmarkOutline,
	bookmark,
	filterCircleOutline,
	reloadCircleOutline,
	reloadOutline,
} from "ionicons/icons";
export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Bottombar,
		Navbar,
		IonRow,
		IonCol,
		IonGrid,
		IonCard,
		IonSpinner,
		IonLabel,
		IonItem,
		IonCheckbox,
		IonText,
		IonIcon,
		IonFab,
		IonBadge,

		IonButton,
	},
	computed: {
		categories() {
			return store.getters.getCategories;
		},

		userProfile() {
			return store.getters.userProfile;
		},

		userSchedule() {
			return store.getters.getUserSchedule;
		},

		featured() {
			return store.getters.getFeaturedClasses;
		},

		classProgress() {
			return store.getters.classProgress;
		},

		bookmarkedClasses() {
			return store.getters.getBookmarkedClasses;
		},
		isWeb() {
			return store.getters.isWeb;
		},
	},

	watch: {
		categories(newCategories, oldCategories) {
			// console.log("CHECKED", this.categories);
		},

		userProfile(newProfile, oldProfile) {
			if (
				this.userProfile.classPreferences &&
				this.userProfile.classPreferences.duration &&
				this.userProfile.classPreferences.category
			) {
				// this.userProfile.classPreferences.category
				this.loading = true;
				this.visibleSection = 10;
				const payload = {
					durations: this.userProfile.classPreferences.duration,
					categories: this.userProfile.classPreferences.category,
				};

				store
					.dispatch(Content.getFilteredClasses, payload)
					.then((filteredClasses) => {
						this.classes = filteredClasses;
						this.filteredClasses = filteredClasses;
						this.loading = false;
					});
			}
		},
	},

	methods: {
		removeBookmark(classObject: firebase.firestore.DocumentData) {
			const payload = {
				classID: classObject.classID,
				userID: store.getters.userData.uid,
			};
			// let alert = null;
			store.dispatch(Content.removeClassBookmark, payload);
		},
		addBookmark(classObject: firebase.firestore.DocumentData) {
			const payload = {
				classID: classObject.classID,
				userID: store.getters.userData.uid,
			};
			store.dispatch(Content.addClassBookmark, payload);
		},

		isBookmarked(classObject: firebase.firestore.DocumentData) {
			const classID = classObject.classID;
			if (
				this.bookmarkedClasses.some(
					(classs: firebase.firestore.DocumentData) =>
						classs.classID === classID
				)
			) {
				return true;
			}

			return false;
		},

		progressType(classID: string) {
			const progress = this.classProgress.filter(
				(classs: firebase.firestore.DocumentData) => classs.classID === classID
			);
			if (progress[0].isFinished) {
				return "Watched";
			}
			return "Watching";
		},
		hasProgressed(classObject: firebase.firestore.DocumentData) {
			const classID = classObject.classID;
			if (
				this.classProgress.some(
					(classs: firebase.firestore.DocumentData) =>
						classs.classID === classID
				)
			) {
				return true;
			}

			return false;
		},
		selectCategory(index: number) {
			this.checkedCategory[index] = !this.checkedCategory[index];

			this.filterPayload();
		},

		selectDuration(index: number) {
			this.selectedDurations[index] = !this.selectedDurations[index];

			this.filterPayload();
		},

		//This function is used to filter the arrays of choices
		//that gets sent to the filter methods, thats basically it
		//I had no better name
		//-Edson
		filterPayload() {
			const filteredCategories = this.categories.filter(
				(category: firebase.firestore.DocumentData, index: number) => {
					return this.checkedCategory.some(
						(element: boolean, index2: number) => {
							return element === true && index === index2;
						}
					);
				}
			);

			const filteredDurations = this.durations.filter(
				(duration: number, index: number) => {
					return this.selectedDurations.some(
						(element: boolean, index2: number) => {
							return element === true && index === index2;
						}
					);
				}
			);

			// console.log("FILTERED", filteredCategories);
			// console.log("FILTERED DURATIONS", filteredDurations);

			return {
				categories: filteredCategories,
				durations: filteredDurations,
			};
		},

		selectVisible() {
			this.visibleSection = this.visibleSection + 1;
		},
		async openModal(classObject: any) {
			// console.log(classObject);
			const modal = await modalController.create({
				component: Modal,
				cssClass: "modal-custom",
				componentProps: {
					title: "Join " + classObject?.name,
					content: classObject?.description || "No Description",
					videoimage: classObject?.imgURL,
					eventdate: classObject.date || {},
					eventname: classObject?.name,
					trainer: classObject?.trainerData.name,
					isLive: classObject?.isLive,
					video: classObject,
					isScheduled: this.isRegistered(classObject.classID),
				},
			});
			return modal.present();
		},

		isRegistered(classID: string) {
			const isScheduled = this.userSchedule.filter(
				(scheduleItem: firebase.firestore.DocumentData) => {
					return scheduleItem.classID === classID;
				}
			);
			if (isScheduled.length === 0) {
				return false;
			}
			return true;
		},

		submitQuestionaire() {
			this.loading = true;
			const payload = {
				uid: store.getters.userData.uid,
				...this.filterPayload(),
			};
			// console.log("PAYLOAD", payload);
			store.dispatch(Auth.setRecommendedChoices, payload).then(() => {
				const payload = {
					durations: this.userProfile.classPreferences.duration,
					categories: this.userProfile.classPreferences.category,
				};
				// console.log("PAYLOAD IN RECOMMMENDED", payload);

				store
					.dispatch(Content.getFilteredClasses, payload)
					.then((filteredClasses) => {
						this.classes = filteredClasses;
						this.filteredClasses = filteredClasses;
						this.loading = false;
					});
			});
		},

		async resubmitQuestionaire() {
			const updateHandler = () => {
				this.loading = true;
				const payload = {
					uid: store.getters.userData.uid,
				};
				store.dispatch(Auth.resetRecommendedChoices, payload).then(() => {
					// console.log("Recommendations have been reset")
					this.visibleSection = 0;
					this.loading = false;
					this.filteredClasses = [];
					this.classes = [];
					// sumbitQuestionaire()
					this.selectedDurations = [false, false, false, false];
					this.checkedCategory = [];
				});
			};

			const cancelHandler = () => {
				// console.log("cancelled")
			};

			const alert = await alertController.create({
				header: "Would You like to Reset Preferences",
				// message: `Do you want to reset classes`,
				buttons: [
					{
						text: "Dismiss",
						handler: cancelHandler,
						cssClass: "alert-recommended-controller",
					},
					{
						text: "Refresh Reccomendations",
						handler: updateHandler,
						cssClass: "color:blue",
					},
				],
			});
			await alert.present();
		},

		// Setter for the filter
		setFilter(finalResult: any) {
			this.filteredClasses = finalResult;
			this.toggleFilter = true;
		},
		// Clear Filter
		showAllClasses() {
			this.filteredClasses = this.classes;
			this.toggleFilter = false;
		},
		toggleHandler(toggleValues: any) {
			let filteredToggleValues = this.toggleValues;
			filteredToggleValues = toggleValues;
			// Update selections
			this.toggleValues = filteredToggleValues;

			const toggleCheck =
				this.toggleValues["live"] ||
				this.toggleValues["onDemand"] ||
				this.toggleValues["liveReplay"] ||
				this.toggleValues["minute10"] ||
				this.toggleValues["minute30"] ||
				this.toggleValues["minute45"] ||
				this.toggleValues["minute60"] ||
				this.toggleValues["bookmarked"] ||
				this.toggleValues["hideWatched"];

			// Check if all Toggles are set to false (XOR)
			if (!toggleCheck) {
				this.toggleFilter = false;
				this.filteredClasses = this.classes;
			}
		},
		// Filter Modal
		async openFilterModal() {
			const modal = await modalController.create({
				component: FilterModal,
				componentProps: {
					classes: this.classes,
					setFilter: this.setFilter,
					showAllClasses: this.showAllClasses,
					filterBookmarked: this.filterBookmarked,
					hideWatched: this.hideWatched,
					toggleHandler: this.toggleHandler,
					toggleValuesInitial: this.toggleValues,
				},
			});
			return modal.present();
		},
		// Filter Bookmarked
		filterBookmarked(payload: any) {
			let classes = payload;
			classes = [];
			payload.forEach((item: firebase.firestore.DocumentData) => {
				if (this.isBookmarked(item)) {
					classes.push(item);
				}
			});
			return classes;
		},
		// Hide Watched Videos
		hideWatched(payload: any) {
			let classes = payload;
			classes.forEach((item: firebase.firestore.DocumentData) => {
				if (this.hasProgressed(item)) {
					const check = this.progressType(item["classID"]) || "";
					if (check === "Watched") {
						classes = classes.filter(
							(data: firebase.firestore.DocumentData) => data != item
						);
					}
				}
			});
			return classes;
		},
	},

	beforeMount: function () {
		const uid = store.getters.userData.uid;

		if (
			this.userProfile.classPreferences &&
			this.userProfile.classPreferences.duration &&
			this.userProfile.classPreferences.category
		) {
			this.loading = true;
			this.visibleSection = 10;
			const payload = {
				durations: this.userProfile.classPreferences.duration,
				categories: this.userProfile.classPreferences.category,
			};
			this.classesLoading = true;
			store
				.dispatch(Content.getFilteredClasses, payload)
				.then((filteredClasses) => {
					this.filteredClasses = filteredClasses;
					this.classes = filteredClasses;
					this.loading = false;
					this.classesLoading = false;
				});
		}

		this.categories.forEach((category: firebase.firestore.DocumentData) => {
			this.checkedCategory.push(false);
		});
	},

	data() {
		return {
			checkedCategory: [] as boolean[],
			durations: [10, 30, 45, 60],
			selectedDurations: [false, false, false, false],
			selectedCategoryIndex: -1,
			selectedWorkoutDuration: -1,
			visibleSection: 0,
			classes: [],
			loading: false,
			classesLoading: false,
			filteredClasses: [],
			toggleFilter: false,
			toggleValues: {
				live: false,
				onDemand: false,
				liveReplay: false,
				minute10: false,
				minute30: false,
				minute45: false,
				minute60: false,
				bookmarked: false,
				hideWatched: false,
				// low: false,
				// moderate: false,
				// high: false,
			},
		};
	},

	setup() {
		return {
			bookmarkOutline,
			bookmark,
			filterCircleOutline,
			alertController,
			reloadCircleOutline,
			reloadOutline,
			IonImg,
		};
	},
});
