<template>
	<ion-content>
		<ion-header
			class="ion-no-border border-bottom"
			style="position: fixed; top: 0"
		>
			<ion-toolbar color="light">
				<ion-title class="text-md">Manage Followers</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-list style="margin-top: 50px">
			<ion-item color="light">
				<ion-label position="floating">Filter by Username</ion-label>
				<ion-input type="text" clear-on-edit="true"></ion-input>
				<ion-button shape="round" color="light" size="block" slot="end"
					>Filter</ion-button
				>
			</ion-item>
			<ion-item color="light" v-for="(user, index) in followers" :key="index">
				<ion-avatar slot="start">
					<img :src="user.avatar" v-if="user.avatar && user.avatar !== ''" />
					<img v-else src="/assets/noavatar.png" />
				</ion-avatar>
				<ion-label
					><h2>{{ user.userName }}</h2>
					<h5 class="text-medium">{{ user.location }}</h5></ion-label
				>
				<ion-button
					v-if="user.isFollower"
					@click="removeFollower(user)"
					slot="end"
					fill="outline"
					color="danger"
					shape="round"
					>Remove</ion-button
				>
				<ion-button disabled slot="end" v-if="followLoading">
					<ion-spinner name="dots"></ion-spinner>
				</ion-button>
				<ion-button
					slot="end"
					@click="followUser(user)"
					v-if="!user.isFollower && !user.isFollowing && !followLoading"
					>Follow</ion-button
				>
				<ion-button
					slot="end"
					@click="followUser(user)"
					v-if="user.isFollower && !user.isFollowing && !followLoading"
					>Follow Back</ion-button
				>
				<ion-button
					slot="end"
					disabled
					v-if="user.isFollowing && !followLoading"
					>Following</ion-button
				>
				<!-- <ion-button slot="end">Follow</ion-button> -->
			</ion-item>
		</ion-list>
	</ion-content>
</template>

<script>
import {
	IonContent,
	loadingController,
	alertController,
	IonButton,
	IonSpinner,
	IonAvatar,
} from "@ionic/vue";

import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { store } from "../../store";
import * as fb from "../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { ActionTypes as Social } from "../../store/modules/social";

export default defineComponent({
	name: "modeFollowers",
	components: {
		IonContent,
		IonButton,
		IonSpinner,
		IonAvatar,
	},
	setup() {
		return {};
	},
	data() {
		return {
			followLoading: false,
		};
	},

	computed: {
		followers() {
			const followers = store.getters.followers;
			// console.log("WE ARE BEING FOLLOWED BY", followers);
			return followers;
		},
	},

	beforeMount: function () {
		store.dispatch(Social.fetchFollowerUsers);
	},

	methods: {
		removeFollower(user) {
			const payload = {
				uid: store.getters.userData.uid,
				unfollowRef: user.uid,
			};
			store.dispatch(Social.removeFollower, payload);
		},
		followUser(user) {
			const payload = {
				uid: store.getters.userData.uid,
				followRef: user.uid,
			};
			store.dispatch(Social.followUser, payload);
		},
		async close() {
			console.log("closing");
			await modalController.dismiss();
		},
	},
});
</script>
