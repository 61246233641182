<template>
	<ion-page>
		<ion-header class="ion-no-border">
			<navbar />
		</ion-header>
		<ion-content :fullscreen="true">
			<div class="page">
				<ion-card class="category-card">
					<ion-grid>
						<ion-row>
							<ion-col size="3" sizeLg="2" offsetLg="2">
								<ion-button
									size="small"
									class="text-sm"
									ref="todayBtn"
									color="primary"
									expand="block"
									fill="clear"
									@click="() => switchpage('today')"
									>Today</ion-button
								>
							</ion-col>
							<ion-col size="3" sizeLg="2">
								<ion-button
									size="small"
									class="text-sm"
									ref="thisWeekBtn"
									color="medium"
									expand="block"
									fill="clear"
									@click="() => switchpage('thisWeek')"
									>This Week</ion-button
								>
							</ion-col>
							<ion-col size="3" sizeLg="2">
								<ion-button
									size="small"
									class="text-sm"
									ref="thisMonthBtn"
									color="medium"
									expand="block"
									fill="clear"
									@click="() => switchpage('thisMonth')"
									>This Month</ion-button
								>
							</ion-col>
							<ion-col size="3" sizeLg="2">
								<ion-button
									size="small"
									class="text-sm"
									ref="alltimeBtn"
									color="medium"
									expand="block"
									fill="clear"
									@click="() => switchpage('alltime')"
									>All Time</ion-button
								>
							</ion-col>
						</ion-row>
						<ion-row class="row-border"></ion-row>
					</ion-grid>
					<!-- LEADERBOARD FOR TODAY -->
					<ion-grid v-if="currentPage == 'today'">
						<ion-row>
							<ion-col size="12" sizeLg="4" offsetLg="4" class="text-center">
								<img src="/assets/leaderboard.png" style="height: 145px" />
								<h2 class="text-sm">Top Performers Today</h2>
							</ion-col>
						</ion-row>
						<ion-row class="row-border text-center"> </ion-row>
						<ion-row>
							<ion-col>
								<ion-list class="light-bg">
									<ion-item
										color="light"
										v-for="(item, index) in leaderboardDataDay"
										:key="index"
									>
										<ion-avatar slot="start">
											<img :src="item.avatar || '/assets/noavatar.jpg'" />
										</ion-avatar>
										<ion-label
											><h2>{{ item.name || "" }}</h2>
											<h5 class="text-medium">
												{{ item.location || "" }}
											</h5></ion-label
										>
										<ion-badge>+ {{ item.xp }} xp</ion-badge>
									</ion-item>
								</ion-list>
							</ion-col>
						</ion-row>
					</ion-grid>
					<!-- LEADERBOARD FOR THIS WEEK -->
					<ion-grid v-if="currentPage == 'thisWeek'">
						<ion-row>
							<ion-col size="12" sizeLg="4" offsetLg="4" class="text-center">
								<img src="/assets/leaderboard.png" style="height: 145px" />
								<h2 class="text-sm">Top Performers this Week</h2>
							</ion-col>
						</ion-row>
						<ion-row class="row-border text-center"> </ion-row>
						<ion-row>
							<ion-col>
								<ion-list class="light-bg">
									<ion-item
										color="light"
										v-for="(item, index) in leaderboardDataWeek"
										:key="index"
									>
										<ion-avatar slot="start">
											<img :src="item.avatar || '/assets/noavatar.jpg'" />
										</ion-avatar>
										<ion-label
											><h2>{{ item.name || "" }}</h2>
											<h5 class="text-medium">
												{{ item.location || "" }}
											</h5></ion-label
										>
										<ion-badge>+ {{ item.xp }} xp</ion-badge>
									</ion-item>
								</ion-list>
							</ion-col>
						</ion-row>
					</ion-grid>
					<!-- LEADERBOARD FOR THIS MONTH -->
					<ion-grid v-if="currentPage == 'thisMonth'">
						<ion-row>
							<ion-col size="12" sizeLg="4" offsetLg="4" class="text-center">
								<img src="/assets/leaderboard2.png" style="height: 145px" />
								<h2 class="text-sm">Top Performers this Month</h2>
							</ion-col>
						</ion-row>
						<ion-row class="row-border text-center"> </ion-row>
						<ion-row>
							<ion-col>
								<ion-list class="light-bg">
									<ion-item
										color="light"
										v-for="(item, index) in leaderboardDataMonth"
										:key="index"
									>
										<ion-avatar slot="start">
											<img :src="item.avatar || '/assets/noavatar.jpg'" />
										</ion-avatar>
										<ion-label
											><h2>{{ item.name || "" }}</h2>
											<h5 class="text-medium">
												{{ item.location || "" }}
											</h5></ion-label
										>
										<ion-badge>+ {{ item.xp }} xp</ion-badge>
									</ion-item>
								</ion-list>
							</ion-col>
						</ion-row>
					</ion-grid>
					<!-- LEADERBOARD FOR ALLTIME -->
					<ion-grid v-if="currentPage == 'alltime'">
						<ion-row>
							<ion-col size="12" sizeLg="4" offsetLg="4" class="text-center">
								<img src="/assets/leaderboard3.png" style="height: 145px" />
								<h2 class="text-sm">Top Performers this year</h2>
							</ion-col>
						</ion-row>
						<ion-row class="row-border text-center"> </ion-row>
						<ion-row>
							<ion-col>
								<ion-list class="light-bg">
									<ion-item
										color="light"
										v-for="(item, index) in leaderboardDataAllTime"
										:key="index"
									>
										<ion-avatar slot="start">
											<img :src="item.avatar || '/assets/noavatar.jpg'" />
										</ion-avatar>
										<ion-label
											><h2>{{ item.name || "" }}</h2>
											<h5 class="text-medium">
												{{ item.location || "" }}
											</h5></ion-label
										>
										<ion-badge>{{ item.xp }} xp</ion-badge>
									</ion-item>
								</ion-list>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-card>
			</div>
		</ion-content>
		<!--  -->
		<bottombar />
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonPage,
	IonCard,
	IonItem,
	modalController,
} from "@ionic/vue";
import { defineComponent, onBeforeMount } from "vue";
import Navbar from "@/components/Navbar.vue";
import Bottombar from "@/components/Bottombar.vue";
import { key, store } from "../store";
import { ActionTypes as leaderboard } from "../store/modules/leaderboard";
import {
	cameraOutline,
	searchOutline,
	personAddOutline,
	downloadOutline,
	trophy,
	shield,
} from "ionicons/icons";

export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
		IonCard,
		IonItem,
	},

	data() {
		return {
			currentPage: "today",
			leaderboardDataDay: [],
			leaderboardDataWeek: [],
			leaderboardDataMonth: [],
			leaderboardDataAllTime: [],
		};
	},

	async beforeMount() {
		// GET LEADERBOARD DATA
		await store
			.dispatch(leaderboard.fetchLeaderboard)
			.then(() => {
				this.leaderboardDataDay = store.getters.leaderboardDay;
				this.leaderboardDataWeek = store.getters.leaderboardWeek;
				this.leaderboardDataMonth = store.getters.leaderboardMonth;
				this.leaderboardDataAllTime = store.getters.leaderboardAllTime;
			})
			.catch((error) => {
				console.log(error);
			});
	},

	methods: {
		switchpage(page = "") {
			this.$refs["todayBtn"].color = "medium";
			this.$refs["thisWeekBtn"].color = "medium";
			this.$refs["thisMonthBtn"].color = "medium";
			this.$refs["alltimeBtn"].color = "medium";
			this.$refs[page + "Btn"].color = "primary";
			this.currentPage = page;
		},
	},

	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
		currentRoute() {
			return this.$route.name;
		},
	},

	setup() {
		return {
			cameraOutline,
			searchOutline,
			personAddOutline,
			downloadOutline,
			trophy,
			shield,
		};
	},
});
</script>

<style scoped>
ion-card {
	box-shadow: 0px 0px 2px 4px rgba(255, 255, 255, 0.05);
}
.camerabtn {
	position: relative;
	top: -30px;
	right: -60px;
}
.settingsbtn {
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
}
.trophy {
	text-align: center;
	width: 40vw;
	margin: 0 auto;
}
</style>
