import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState, store } from "@/store";
import moment, { Moment } from "moment";
import { Getters } from "./types";
import { classSortDesc } from "@/utils/utils";
import { ClassData } from "@/interfaces/contentStore";

export const getters: GetterTree<State, RootState> & Getters = {
	getCategories: (state) => {
		return state.categories;
	},

	getClasses: (state) => {
		return state.classes;
	},

	getClassesByCategory: (state) => (category: string) => {
		// TODO
		const classes = state.classes.filter((classObject) => {
			return classObject.categoryName === category;
		});

		return classes.sort(classSortDesc);
	},

	getClassesByTrainer: (state) => (trainerId: string) => {
		const classes = state.classes.filter((classs) => {
			// console.log(store.getters.currentTrainer)
			if (classs.trainerData) {
				// console.log("classss", classs)
				const isHere = classs?.trainerData?.id == trainerId;
				return isHere;
			}

			return false;
		});

		return classes.sort(classSortDesc);
	},

	getLiveClasses: (state) => {
	
		// this allows us to join classes at any point during their duration
		const data = state.classes?.filter((classObject) => {
			return (
				classObject.isLive &&
				moment(new Date(Date.now())).isSameOrBefore(
					new Date((classObject.date.seconds + classObject.duration * 60) *1000)
				)
			);
		});
		
		const sorted = data.sort((firstClass: any, secondClass: any) => {
			const firstDate = moment(firstClass.date.seconds * 1000);
			const secondDate = moment(secondClass.date.seconds * 1000);
			const firstAfterSec = firstDate.isAfter(secondDate);
			const secAfterFirst = secondDate.isAfter(firstDate);
			if (firstAfterSec) {
				return 1;
			} else if (secAfterFirst) {
				return -1;
			} else {
				return 0;
			}
		});

		return sorted;
	},

	getFeaturedClasses: (state) => {
		const featured = state.classes.filter((classObject) => {
			return classObject.isFeatured;
		});

		return featured.sort(classSortDesc);
	},

	getClassesForDay: (state) => (date: Moment) => {
		const live = store.getters.getLiveClasses;
		const scheduleClass = store.getters.getUserSchedule;
		const liveClassIDs = [] as string[];

		// console.log("USER SCHEDULE", scheduleClass);

		const todayClass = live.filter((classObject: any) => {
			const classDate = moment(new Date(classObject.date.seconds * 1000));
			classObject["dateMoment"] = classDate;
			liveClassIDs.push(classObject.classID);
			return classDate.isSame(date, "day");
		});

		// console.log(liveClassIDs);
		// console.log(todayClass);

		//Filter the live classes from schedule List
		let filteredVod: any[] = scheduleClass.filter((classObject: any) => {
			return !liveClassIDs.includes(classObject.classID);
		});

		//filter out the classes that are not happening today
		filteredVod = filteredVod.filter((classObject: any) => {
			const classDate = moment(new Date(classObject.date.seconds * 1000));
			classObject["dateMoment"] = classDate;
			// console.log(classObject);
			return classDate.isSame(date, "day");
		});

		// console.log(filteredVod);
		const scheduledVODs = [] as ClassData[];
		filteredVod.forEach((vod) => {
			const data = store.getters.getClass(vod.classID);
			// console.log(data);

			data["dateMoment"] = vod.dateMoment;
			scheduledVODs.push(data);
		});

		const fullSchedule: any[] = todayClass.concat(scheduledVODs);

		//sort the output
		fullSchedule.sort((class1, class2) =>
			class1.dateMoment.isAfter(class2.dateMoment) ? 1 : -1
		);
		// console.log(fullSchedule);
		return fullSchedule;
	},

	getClass: (state) => (id: string) => {
		return state.classes.find((obj) => obj.classID === id);
	},

	getBookmarkedClasses: (state) => {
		if (store.getters.userData) {
			return state.bookmarkedClasses;
		}

		return [];
	},
};
