<template>
	<ion-content>
		<ion-header
			class="ion-no-border border-bottom"
			style="position: fixed; top: 0"
		>
			<ion-toolbar color="light">
				<ion-title class="text-md">Members you Follow</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close()">X</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-list style="margin-top: 50px">
			<ion-item color="light">
				<ion-label position="floating">Filter by Username</ion-label>
				<ion-input type="text" clear-on-edit="true"></ion-input>

				<ion-button shape="round" color="light" size="block" slot="end"
					>Filter</ion-button
				>
			</ion-item>
			<ion-item color="light" v-for="(user, index) in following" :key="index">
				<ion-avatar slot="start">
					<img :src="user.avatar" v-if="user.avatar && user.avatar !== ''" />
					<img v-else src="/assets/noavatar.png" />
				</ion-avatar>
				<ion-label
					><h2>{{ user.userName }}</h2>
					<h5 class="text-medium">{{ user.location }}</h5></ion-label
				>

				<ion-button slot="end" @click="unfollow(user)">Unfollow</ion-button>
			</ion-item>
		</ion-list>
	</ion-content>
</template>

<script>
import {
	IonContent,
	loadingController,
	alertController,
	IonButton,
} from "@ionic/vue";

import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { store } from "../../store";
import * as fb from "../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { ActionTypes as Social } from "../../store/modules/social";

export default defineComponent({
	name: "ModalFollowing",
	components: {
		IonContent,
		IonButton,
	},
	setup() {
		return {};
	},
	data() {
		return {};
	},

	computed: {
		following() {
			const following = store.getters.following;
			// console.log("WE ARE FOLLOWING", following)
			return following;
		},
	},

	beforeMount: function () {
		store.dispatch(Social.fetchFollowingUsers);
	},

	methods: {
		async close() {
			console.log("closing");
			await modalController.dismiss();
		},

		unfollow(user) {
			const payload = {
				uid: store.getters.userData.uid,
				unfollowRef: user.uid,
			};

			store.dispatch(Social.unfollowUser, payload);
		},
	},
});
</script>
