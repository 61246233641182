import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import { store } from "@/store";
import { Getters } from "./types";

// [D.2] - declare the getter
// =>  x: (state) => {
// =>    return state.x;
// =>  },
export const getters: GetterTree<State, RootState> & Getters = {
	currentVideo: (state) => {
		return state.currentVideo;
	},

	currentVideoRecord: (state) => {
		return state.currentVideoRecord;
	},

	videoViewers: (state) => {
		return state.usersWatching;
	},

	classProgress: (state) => {
		// console.log("CHECKING IF USER EXISTS");
		if (store.getters.userData) {
			return state.userClassProgress;
		}

		return [];
	},
};
