
import {
	IonContent,
	IonHeader,
	IonPage,
	loadingController,
	alertController,
	IonInput,
	IonImg,
	IonLabel,
	IonButton,
	IonCard,
	IonGrid,
	IonRow,
	IonCol,
	isPlatform,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { store } from "../store";
import localStore from "../utils/localstore";
import { ActionTypes as Auth } from "../store/modules/auth";
// import { Storage } from "@ionic/storage";
import Navbar from "../components/Navbar.vue";

export default defineComponent({
	name: "Login",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		IonInput,
		IonImg,
		IonLabel,
		IonButton,
		IonCard,
		IonGrid,
		IonRow,
		IonCol,
	},

	data() {
		return {
			email: "",
			password: "",
			itemData: {},
		};
	},

	methods: {
		async login() {
			const payload = {
				email: this.email,
				password: this.password,
				store: localStore,
			};
			const loading = await loadingController.create({
				message: "Please wait...",
			});
			await loading.present();

			store
				.dispatch(Auth.loginUser, payload)
				.then(() => {
					loading.dismiss();
					if (!store.getters.userData.subscription.isSubscribed) {
						this.$router.push("/subscribe");
					} else {
						if (isPlatform("mobile") || isPlatform("mobileweb")) {
							this.$router.push("/classes");
						} else {
							this.$router.push("/");
						}
					}
				})
				.catch(async (error) => {
					loading.dismiss();
					const alert = await alertController.create({
						header: "Login Failed",
						message: error.message,
						buttons: ["OK"],
					});
					alert.present();
				});
		},
		async socialAuth(provider: string) {
			const loading = await loadingController.create({
				message: "Please wait...",
			});
			await loading.present();

			const payload = {
				provider,
				store: localStore,
				type: "signin",
			};

			store
				.dispatch(Auth.socialAuth, payload)
				.then(() => {
					loading.dismiss();
					if (!store.getters.userData.subscription.isSubscribed) {
						this.$router.push("/subscribe");
					} else {
						if (isPlatform("mobile") || isPlatform("mobileweb")) {
							this.$router.push("/classes");
						} else {
							this.$router.push("/");
						}
					}
				})
				.catch(async (error) => {
					loading.dismiss();
					const alert = await alertController.create({
						header: "Login Failed",
						message: error.message,
						buttons: ["OK"],
					});
					alert.present();
				});
		},
	},
	computed: {
		client() {
			return store.getters.client;
		},
	},
	setup() {
		let isWeb = false;
		if (isPlatform("mobile") || isPlatform("mobileweb")) {
			isWeb = false;
		} else {
			isWeb = true;
		}
		return { isWeb };
	},
});
