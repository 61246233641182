import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import { store } from "../../";
// Actions
// place the action definition as a string
// ==> { setX = "MODULE__SET_ X" }
export enum ActionTypes {
	fetchPrograms = "FETCH_PROGRAMS",
	checkValidProgram = "CHECK_VALID_PROGRAM",
	reset = "RESET",
}

// !!! AUGUMENTED ACTION CONTEXT !!!
type AugmentedActionContext = {
	commit<K extends keyof Mutations>(
		key: K,
		payload: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

// [C.2] define Action types
// ==>  [ActionTypes.action](
// ==>    { commit }: AugmentedActionContext,
// ==>    payload: DAATA TYPE that the action is taking in
// ==>  ): void;  // I asssume the void can change
export interface Actions {
	[ActionTypes.fetchPrograms](
		{ commit }: AugmentedActionContext,
		payload: any
	): void;
	[ActionTypes.checkValidProgram](
		{ commit }: AugmentedActionContext,
		payload: any
	): boolean;
}

// [C.3] declare actions
// [ActionTypes.setX]({ commit }, payload) {
//   commit(MutationTypes.X, payload);
// },
export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.fetchPrograms](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		const programs = await fb.programs
			.get()
			.then((querySnapshot: firebase.firestore.QuerySnapshot) => {
				const program: firebase.firestore.DocumentData[] = [];
				querySnapshot.forEach((doc) => {
					program.push({ ...doc.data(), id: doc.id });
				});
				return program;
			});

		commit(MutationTypes.SET_PROGRAMS, programs);
	},

	[ActionTypes.checkValidProgram](
		{ commit }: AugmentedActionContext,
		classRefs: firebase.firestore.DocumentReference[]
	) {
		const data: firebase.firestore.DocumentData = [];
		console.log(classRefs);
		const dat = store.getters.getCategories;

		dat.forEach((category: any) => {
			console.log(category);

			classRefs.forEach((ref: firebase.firestore.DocumentReference) => {
				console.log(ref);
				let isContained = false;
				category.classes.forEach(
					(clas: firebase.firestore.DocumentReference) => {
						if (ref.id === clas.id) {
							isContained = true;
						}
					}
				);

				if (!isContained) {
					return false;
				}
			});
		});
		return true;
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},

	//more actions
};
