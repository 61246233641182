import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";

// (D) - Getters
// [D.1] - Define the getter type
// => x(state: S): TYPE_OF_X;
export type Getters<S = State> = {
	programs(state: S): firebase.firestore.DocumentData[];
};

// [D.2] - declare the getter
// =>  x: (state) => {
// =>    return state.x;
// =>  },
export const getters: GetterTree<State, RootState> & Getters = {
	programs: (state) => {
		return state.programs;
	},
};
