<template>
	<ion-header class="ion-no-border border-bottom">
		<ion-toolbar color="light">
			<ion-title class="text-lg">Reset Password</ion-title>
			<ion-buttons slot="end">
				<ion-button @click="close()">X</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content color="dark" class="ion-padding text-center">
		<ion-grid>
			<ion-row>
				<ion-col>
					<ion-label position="floating">Password</ion-label>
					<ion-input
						style="border: 1px solid #fff; margin: 10px 0 30px 0"
						autocomplete="off"
						type="password"
						v-model="password"
						v-bind:value="password"
						v-on:ionInput="password = $event.target.value"
					></ion-input>
					<ion-label>Confirm</ion-label>
					<ion-input
						style="border: 1px solid #fff; margin: 10px 0 0px 0"
						autocomplete="off"
						type="password"
						v-model="confirm"
						v-bind:value="confirm"
						v-on:ionInput="confirm = $event.target.value"
					></ion-input>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					<br /><br />
					<ion-button color="light" size="block" @click="updatePassword()"
						>Update</ion-button
					>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-content>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButton,
	alertController,
	loadingController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import {
	star,
	starOutline,
	starHalf,
	thumbsDownOutline,
	thumbsUpOutline,
	trophyOutline,
	timerOutline,
	flameOutline,
	heartOutline,
} from "ionicons/icons";
import { ActionTypes as Auth } from "../../store/modules/auth";
import { store } from "@/store";

export default defineComponent({
	name: "Modal",

	setup() {
		return {
			star,
			starOutline,
			starHalf,
			thumbsDownOutline,
			thumbsUpOutline,
			trophyOutline,
			timerOutline,
			flameOutline,
			heartOutline,
		};
	},
	data() {
		return {
			password: "",
			confirm: "",
		};
	},
	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButton,
	},
	computed: {},
	methods: {
		async close() {
			await modalController.dismiss();
		},

		async updatePassword() {
			const alert = await alertController.create({
				header: "Password Reset Failed",
				message: "",
				buttons: ["Close"],
			});

			// console.log("1", this.password, "2", this.confirm);
			if (this.password === "" || this.confirm === "") {
				alert.message = "Please input the password in both fields!";
				alert.present();
			} else if (this.password !== this.confirm) {
				alert.message = "Passwords do not match!";
				alert.present();
			} else if (this.password === this.confirm) {
				const loading = await loadingController.create({
					message: "Please wait...",
				});
				await loading.present();
				store
					.dispatch(Auth.updatePassword, this.password)
					.then(() => {
						loading.dismiss();
						modalController.dismiss();

						alert.header = "Password Reset Successful";
						alert.message =
							"You can use login with your new password next time";
						alert.present();
					})
					.catch(async (error) => {
						loading.dismiss();
						alert.message = error.message;
						alert.present();
					});
			}
		},
	},
});
</script>
