import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { Actions } from "./interfaces";
import { State } from "./index";
import { RootState } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import axios from "axios";
import { store } from "@/store";
import { ActionTypes as VideoPlayer } from "../video_player/actions";
import { ActionTypes as Content } from "../content/actions";
import { ActionTypes as Auth } from "../auth/actions";
import localStore from "@/utils/localstore";

// Actions
// place the action definition as a string
// ==> { setX = "MODULE__SET_ X" }
export enum ActionTypes {
	setUserData = "SET_USER_DATA",
	loginUser = "LOGIN_USER_WITH_CREDENTIALS",
	updatePassword = "UPDATE_PASSWORD",
	updateUserProfile = "UPDATE_USER_PROFILE",
	signupUser = "SIGNUP_USER",
	socialAuth = "SOCIAL_AUTH",
	fetchClientData = "FETCH_CLIENT_DATA",
	userProfile = "GET_USER_PROFILE",
	updateSubscription = "UPDATE_SUBSCRIPTION",
	updateAboutUS = "UPDATE_ABOUTUS",
	updateSupport = "UPDATE_SUPPORT",
	setRecommendedChoices = "SET_RECOMMEND_ACTION",
	resetRecommendedChoices = "RESET_RECOMMENDED_ACTION",
	resetPassword = "RESET_PASSWORD",
	onboardUser = "ONBOARD_USER",
	addUserToken = "ADD_TOKEN",
	addXP = "ADD_EXPERIENCE_POINTS",
	reset = "RESET",
	fetchUserXP = "FETCH_USER_EXPERIENCE_POINTS",
	updateFetchingProfile = "FETCHING_PROFILE_STATE",
}

// [C.3] declare actions
// [ActionTypes.setX]({ commit }, payload) {
//   commit(MutationTypes.X, payload);
// },
export const actions: ActionTree<State, RootState> & Actions = {
	[ActionTypes.updateFetchingProfile]({ commit }, payload) {
		commit(MutationTypes.setFetchingProfile, payload);
	},

	// ADD EXPERIENCE POINTS TO XP SUB-COLLECTION
	async [ActionTypes.addXP]({ commit }, data) {
		// Get uid
		const uid = fb.auth.currentUser?.uid;
		// Add timestamp
		const payload = {
			...data,
			timestamp: firebase.firestore.Timestamp.now(),
		};
		// Make Firebase call to add XP points
		new Promise((resolve, reject) => {
			const docRef = fb.db.collection("users").doc(uid).collection("XP");
			docRef
				.add(payload)
				.then((res) => resolve(res))
				.catch((error) => reject(error));
		});
	},

	// FETCH USER XP POINTS
	async [ActionTypes.fetchUserXP]({ commit }) {
		// Get uid
		const uid = fb.auth.currentUser?.uid;

		const userXP: firebase.firestore.DocumentData[] = [];

		const docRef = fb.db.collection("users").doc(uid).collection("XP");

		docRef.get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				userXP.push(doc.data());
			});

			commit(MutationTypes.getUserXP, {
				...userXP,
			});
		});
	},

	// SET USER DATA
	async [ActionTypes.setUserData]({ commit }, payload) {
		commit(MutationTypes.setUserData, payload);
	},
	// UPDATE USER PASSWORD
	async [ActionTypes.updatePassword]({ commit }, payload) {
		return new Promise((resolve, reject) => {
			fb.auth.currentUser
				?.updatePassword(payload)
				.then(() => resolve())
				.catch((err) => reject(err));
		});
	},
	// UPDATE USER PROFILE
	async [ActionTypes.updateUserProfile]({ commit }, payload) {
		const uid = payload.uid;

		// Remove uid from payload
		delete payload["uid"];

		// console.log("UPDATE USER PROFILE PAYLOAD", JSON.stringify(payload));

		await new Promise((resolve, reject) => {
			fb.db
				.collection("users")
				.doc(uid)
				.update(payload)
				.then((res) => resolve(res))
				.catch((error) => reject(error));
		});
	},
	// UPDATE ABOUT US
	async [ActionTypes.updateAboutUS]({ commit }, payload) {
		const updateAboutUS = firebase.functions().httpsCallable("updateAboutUS");
		return new Promise((resolve, reject) => {
			updateAboutUS({ payload })
				.then(({ data }) => {
					commit(MutationTypes.setCurrentClient, data.data);
					resolve();
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	// UPDATE SUPPORT
	async [ActionTypes.updateSupport]({ commit }, payload) {
		const updateSupport = firebase.functions().httpsCallable("updateSupport");
		return new Promise((resolve, reject) => {
			updateSupport({ payload })
				.then(({ data }) => {
					commit(MutationTypes.setCurrentClient, data.data);
					resolve();
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	// UPDATE USER SUBSCRIPTION
	async [ActionTypes.updateSubscription]({ commit }, payload) {
		const userSubscribe = firebase.functions().httpsCallable("userSubscribe");
		return new Promise((resolve, reject) => {
			userSubscribe({ trial: payload })
				.then(({ data }) => {
					commit(MutationTypes.userProfile, data.data);
					resolve();
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	// USER LOGIN
	async [ActionTypes.loginUser]({ commit }, payload) {
		store.dispatch(ActionTypes.setUserData, undefined);
		store.dispatch(ActionTypes.updateFetchingProfile, true);
		return new Promise((resolve, reject) => {
			fb.auth
				.signInWithEmailAndPassword(payload.email, payload.password)
				.then((response: firebase.auth.UserCredential) => {
					// Store refreshtoken in the local storage
					payload.store.set("refreshtoken", response.user?.refreshToken);
					//Get User Object
					fb.users
						.doc(response.user?.uid)
						.get()
						.then(async (res) => {
							//Store user object from firebase in local storage
							//JSON.stringify as objects in the local storage have to be a string
							//When calling store.get('user'), make sure to JSON.parse the response
							const updatedUser = {
								...res.data(),
								uid: response.user?.uid,
							};

							store.dispatch(VideoPlayer.fetchWatchHistory, {
								userID: response.user?.uid,
							});
							store.dispatch(Content.fetchBookmarkedClasses, {
								userID: response.user?.uid,
							});

							const token = await localStore.get("fcmToken");
							if (token) {
								// console.log("DISPATCHING");
								store.dispatch(Auth.addUserToken, {
									userID: response.user?.uid,
									token: token,
								});
							}

							store
								.dispatch(ActionTypes.userProfile, response.user?.uid)
								.then(() =>
									store.dispatch(ActionTypes.updateFetchingProfile, false)
								);

							// console.log("user", updatedUser);
							payload.store.set("user", updatedUser);
							commit(MutationTypes.setUserData, updatedUser);
							resolve();
						})
						.catch((err) => reject(err));
				})
				.catch((err) => reject(err));
		});
	},
	// NEW USER SIGNUP
	async [ActionTypes.signupUser]({ commit }, payload) {
		store.dispatch(ActionTypes.setUserData, undefined);
		store.dispatch(ActionTypes.updateFetchingProfile, true);

		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.createUserWithEmailAndPassword(payload.email, payload.password)
				.then(async (response: firebase.auth.UserCredential) => {
					console.log("create user response", response);
					const id = response.user?.uid;

					fb.users
						.doc(id)
						.get()
						.then(async (res) => {
							const data = {
								clientId: process.env.VUE_APP_CLIENT_KEY,
								firebaseId: id,
								name: payload.name,
								email: payload.email,
								password: btoa(payload.password + "|OurLittleSecret"), //We should actually add the secret to the ENV along with updating the ENV on the CRM app.
							};
							const updatedUser = {
								...res.data(),
								uid: id,
								name: payload.name,
							};
							// ADD EXPERIENCE POINTS FOR SIGNING UP
							store
								.dispatch(ActionTypes.addXP, {
									name: "SignUp",
									value: 100,
								})
								.catch((error) => {
									console.log("Error adding XP!", error.message);
								});
							store
								.dispatch(ActionTypes.userProfile, id)
								.then(() =>
									store.dispatch(ActionTypes.updateFetchingProfile, false)
								);
							store.dispatch(VideoPlayer.fetchWatchHistory, {
								userID: id,
							});
							store.dispatch(Content.fetchBookmarkedClasses, {
								userID: id,
							});

							const token = await localStore.get("fcmToken");
							if (token) {
								// console.log("DISPATCHING");
								store.dispatch(Auth.addUserToken, { userID: id, token: token });
							}

							commit(MutationTypes.setUserData, updatedUser);
							payload.store.set("user", updatedUser);
							payload.store.set("refreshtoken", response.user?.refreshToken);

							await axios
								.post(process.env.VUE_APP_CRM_ENDPOINT, data)
								.then(async (res) => {
									console.log("sign up crm response");
								})
								.catch((err) => {
									console.log("sign up crm error");
									// throw err;
									reject(err);
								});

							console.log("UPDATING USERS");
							const userRef = fb.users.doc(id);

							userRef.get().then((docSnapshot) => {
								if (docSnapshot.exists) {
									const listening = userRef.onSnapshot(async (doc) => {
										// console.log(doc.data());
										// do stuff with the data

										// Update user data in firestore
										await userRef.update({
											name: payload.name,
											email: payload.email,
											client: process.env.VUE_APP_CLIENT_KEY,
										});
									});

									listening();
								}
							});

							// unsubscribe();
							resolve(res);
						})
						.catch((err) => {
							console.log(err);
							// throw err;
							reject(err);
						});
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
	},
	// SOCIAL AUTHENTICATION
	async [ActionTypes.socialAuth]({ commit }, payload) {
		// Google
		if (payload.provider === "google") {
			const provider = new firebase.auth.GoogleAuthProvider();
			console.log(provider);
			provider.addScope("https://www.googleapis.com/auth/userinfo.email");

			if (payload.type === "signin") {
				store.dispatch(ActionTypes.setUserData, undefined);
				store.dispatch(ActionTypes.updateFetchingProfile, true);

				return new Promise((resolve, reject) => {
					firebase
						.auth()
						.signInWithPopup(provider)
						.then((result) => {
							const additionalUserInfo = result.additionalUserInfo;
							// This gives you a Google Access Token. You can use it to access the Google API.
							const credential = result.credential;
							// const token = credential.accessToken;

							// The signed-in user info.
							const user = result.user;
							// Store refreshtoken in the local storage
							payload.store.set("refreshtoken", user?.refreshToken);

							console.log(
								"additionalUserInfo",
								additionalUserInfo,
								"credential",
								credential,
								"user",
								user
							);

							fb.users
								.doc(user?.uid)
								.get()
								.then(async (res) => {
									console.log("social sign in response data", res.data());
									//Store user object from firebase in local storage
									//JSON.stringify as objects in the local storage have to be a string
									//When calling store.get('user'), make sure to JSON.parse the response
									const updatedUser = {
										...res.data(),
										uid: user?.uid,
									};

									await store.dispatch(VideoPlayer.fetchWatchHistory, {
										userID: user?.uid,
									});
									await store.dispatch(Content.fetchBookmarkedClasses, {
										userID: user?.uid,
									});

									const token = await localStore.get("fcmToken");
									if (token) {
										store.dispatch(Auth.addUserToken, {
											userID: user?.uid,
											token: token,
										});
									}

									await store
										.dispatch(ActionTypes.userProfile, user?.uid)
										.then(() =>
											store.dispatch(ActionTypes.updateFetchingProfile, false)
										);

									console.log("user", updatedUser);
									payload.store.set("user", updatedUser);
									commit(MutationTypes.setUserData, updatedUser);
									resolve();
								})
								.catch((err) => reject(err));
						})
						.catch((error) => {
							// Handle Errors here.
							const errorCode = error.code;
							const errorMessage = error.message;
							// The email of the user's account used.
							const email = error.email;
							// The AuthCredential type that was used.
							const credential = error.credential;
							const errObj = { errorCode, errorMessage, email, credential };
							console.log("google auth error", errObj);
							reject(errObj);
						});
				});
			} else {
				// Sign up
				store.dispatch(ActionTypes.setUserData, undefined);
				store.dispatch(ActionTypes.updateFetchingProfile, true);

				return new Promise((resolve, reject) => {
					console.log("social sign up");
					firebase
						.auth()
						.signInWithPopup(provider)
						.then(async (result) => {
							const additionalUserInfo = result.additionalUserInfo;
							const profile = additionalUserInfo?.profile;
							// This gives you a Google Access Token. You can use it to access the Google API.
							const credential = result.credential;
							// const token = credential.accessToken;
							// The signed-in user info.
							const user = result.user;
							console.log(
								"additionalUserInfo",
								additionalUserInfo,
								"credential",
								credential,
								"user",
								user,
								"profile",
								profile
							);
							const id = result.user?.uid;

							await fb.users
								.doc(id)
								.get()
								.then(async (res) => {
									const data = {
										...profile,
										clientId: process.env.VUE_APP_CLIENT_KEY,
										firebaseId: id,
										name: result.user?.displayName,
										password: "",
									};
									console.log("data", data);
									const updatedUser = {
										...res.data(),
										uid: id,
										name: result.user?.displayName,
									};
									// ADD EXPERIENCE POINTS FOR SIGNING UP
									store
										.dispatch(ActionTypes.addXP, {
											name: "SignUp",
											value: 100,
										})
										.catch((error) => {
											console.log("Error adding XP!", error.message);
										});
									store
										.dispatch(ActionTypes.userProfile, id)
										.then(() =>
											store.dispatch(ActionTypes.updateFetchingProfile, false)
										);
									store.dispatch(VideoPlayer.fetchWatchHistory, {
										userID: id,
									});
									store.dispatch(Content.fetchBookmarkedClasses, {
										userID: id,
									});
									const token = await localStore.get("fcmToken");
									if (token) {
										console.log("social sign up token", token);
										store.dispatch(Auth.addUserToken, {
											userID: id,
											token: token,
										});
									}
									commit(MutationTypes.setUserData, updatedUser);
									payload.store.set("user", updatedUser);
									payload.store.set("refreshtoken", result.user?.refreshToken);
									await axios
										.post(process.env.VUE_APP_CRM_ENDPOINT, data)
										.then((res) => {
											console.log("signup crm response", res);
											// resolve();
										})
										.catch((err) => {
											console.log("signup crm error", err);
											reject(err);
										});
								})
								.catch((err) => {
									console.log("social signup get user error", err);
									reject(err);
								});

							// Update user data in firestore
							await fb.users.doc(id).update({
								client: process.env.VUE_APP_CLIENT_KEY,
								...profile,
							});

							resolve();
						});
				});
			}
		} else if (payload.provider === "facebook") {
			// Facebook
			const provider = new firebase.auth.FacebookAuthProvider();
			console.log(provider);
			provider.addScope("email");
		}
	},
	// GET CLIENT DATA
	async [ActionTypes.fetchClientData]({ commit }, payload) {
		// console.log("clientPayload", payload)
		//clear the error
		commit(MutationTypes.resetClientError, undefined);

		fb.clientCollection
			.doc(payload)
			.get()
			.then((documentSnapshot: firebase.firestore.DocumentSnapshot) => {
				const data = documentSnapshot.data();

				if (data) {
					delete data.categories;
				}

				// console.log("CLIENT",data);
				commit(MutationTypes.setCurrentClient, {
					...data,
					id: documentSnapshot.id,
				});
			})
			.catch((error: firebase.FirebaseError) => {
				commit(MutationTypes.setClientError, error); //commit the error to store
			});
	},
	// GET USER PROFILE
	async [ActionTypes.userProfile]({ commit }, payload) {
		// {uid, ...userData} {uid, userData :{ ... }}
		const userDocumentSnapShot = await fb.users.doc(payload).get();

		// const userDocumentSnapShot = await fb.users.doc(payload.uid).update({userName: payload.userName})

		// console.log("user data", userDocumentSnapShot?.data());

		commit(MutationTypes.userProfile, {
			...userDocumentSnapShot.data(),
			uid: userDocumentSnapShot.id,
		});

		// console.log("commited");
	},
	// SET RECOMMENDATIONS
	async [ActionTypes.setRecommendedChoices]({ commit }, payload) {
		// console.log("PAYLOAD", payload)
		// console.log("PAYLOAD DATA", payload.data)
		const classPreferences = {
			category: payload.categories.reduce(function (
				accumulator: any,
				currentValue: any
			) {
				return accumulator.concat({
					name: currentValue.name,
					categoryId: currentValue.categoryId,
				});
			},
			[]),
			duration: payload.durations,
		};

		return fb.users
			.doc(payload.uid)
			.update({ classPreferences: classPreferences })
			.then(async () => {
				await store.dispatch(ActionTypes.userProfile, payload.uid);
			});
	},
	// RESET USER PASSWORD
	async [ActionTypes.resetPassword]({ commit }, payload) {
		return new Promise((resolve, reject) => {
			fb.auth
				.sendPasswordResetEmail(payload)
				.then(() => {
					//Password reset email sent
					resolve();
				})
				.catch((err) => reject(err));
		});
	},
	// ONBOARD USER
	async [ActionTypes.onboardUser]({ commit }, payload) {
		return new Promise((resolve, reject) => {
			fb.db
				.collection("users")
				.doc(payload.uid)
				.update({
					userName: payload.userName,
					location: payload.location,
				})
				.then(() => {
					resolve();
				})
				.catch((error) => reject(error));
		});
	},
	// RESET RECOMMENDATIONS
	async [ActionTypes.resetRecommendedChoices]({ commit }, payload) {
		const classPreferences = {
			category: null,
			duration: null,
		};
		await fb.users
			.doc(payload.uid)
			.update({ classPreferences: classPreferences })
			.then(async () => {
				await store.dispatch(ActionTypes.userProfile, payload.uid);
			});
	},
	// ADD USER TOKEN
	async [ActionTypes.addUserToken]({ commit }, payload) {
		const ref = fb.users.doc(payload.userID).collection("fcmTokens");

		ref.get().then((querySnapshot: firebase.firestore.QuerySnapshot) => {
			if (querySnapshot.docs.length > 0) {
				//check that we do not have the token already
				let found = false;

				querySnapshot.docs.every(
					(document: firebase.firestore.DocumentSnapshot) => {
						const data = document.data();

						if (data?.token && data.token === payload.token) {
							found = true;
							return false;
						}
					}
				);

				if (!found) {
					ref.add({ token: payload.token });
				}
			} else {
				ref.add({ token: payload.token });
			}
		});
	},
	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
