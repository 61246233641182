
import {
	IonTabBar,
	IonTabButton,
	IonLabel,
	IonButton,
	IonToolbar,
	IonAvatar,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
	personCircle,
	playCircleOutline,
	starSharp,
	menuOutline,
	trophyOutline,
} from "ionicons/icons";
import { RouteRecordName } from "vue-router";
import { store } from "../store";
export default defineComponent({
	name: "Bottombar",
	components: {
		IonToolbar,
		IonButton,
	},
	data() {
		return {
			bottomNav: ["FEATURED", "CLASSES", "PROFILE"],
			bottomIcons: [starSharp, playCircleOutline, personCircle],
			pageName: "" as RouteRecordName | undefined | null,
		};
	},

	beforeMount: function () {
		this.pageName = this.$route.name;
	},

	setup() {
		return {
			personCircle,
			playCircleOutline,
			starSharp,
			trophyOutline,
			menuOutline,
			IonAvatar,
		};
	},
	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
	},
});
