<template>
	<ion-content
		:color="client?.mode != 'dark' ? 'dark' : ''"
		class="ion-padding text-center"
	>
		<ion-grid>
			<ion-row>
				<ion-col>
					<h2>{{ title }}</h2>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col> <br /><br /> </ion-col>
			</ion-row>
			<ion-row class="mobile-only">
				<ion-col> <br /><br /> </ion-col>
			</ion-row>
			<ion-row>
				<ion-col> How would you rate this trainer?<br /><br /> </ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					<ion-icon
						button
						@click="rating = 1"
						v-bind:color="light"
						:icon="getIcon(1)"
					></ion-icon
					><br />1
				</ion-col>

				<ion-col>
					<ion-icon
						button
						@click="rating = 2"
						v-bind:color="light"
						:icon="getIcon(2)"
					></ion-icon
					><br />2
				</ion-col>
				<ion-col>
					<ion-icon
						button
						@click="rating = 3"
						v-bind:color="light"
						:icon="getIcon(3)"
					></ion-icon
					><br />3
				</ion-col>
				<ion-col>
					<ion-icon
						button
						@click="rating = 4"
						v-bind:color="light"
						:icon="getIcon(4)"
					></ion-icon
					><br />4
				</ion-col>
				<ion-col>
					<ion-icon
						button
						@click="rating = 5"
						v-bind:color="light"
						:icon="getIcon(5)"
					></ion-icon
					><br />5
				</ion-col>
			</ion-row>

			<ion-row class="centerLabel">
				<ion-textarea
					v-model="review"
					class="ratingTextBox"
					aria-placeholder="Leave a review..."
				>
				</ion-textarea>
			</ion-row>

			<ion-row>
				<ion-col>
					<ion-button
						@click="updateRating()"
						:color="client?.mode != 'dark' ? 'light' : 'dark'"
						size="block"
						>Done</ion-button
					>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-content>
</template>

<script>
import {
	IonContent,
	// IonHeader,
	// IonTitle,
	// IonToolbar,
	// IonButtons,
	// IonInput,
	IonButton,
	// IonLabel,
	IonTextarea,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { star, starOutline, starHalf } from "ionicons/icons";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
//
import { store } from "../../store";
import { ActionTypes as Auth } from "../../store/modules/auth";

export default defineComponent({
	name: "TrainerModal",
	props: {
		trainer: { default: "" },
		user: { default: "" },
		title: { default: "" },
		client: { default: "" },
	},
	setup() {
		return {
			star,
			starOutline,
			starHalf,
		};
	},
	data() {
		return {
			// content: 'Content',
			rating: 0,
			review: "",
		};
	},
	components: {
		IonContent,
		// IonHeader,
		// IonTitle,
		// IonToolbar,
		// IonButtons,
		IonButton,
		// IonLabel,
		IonTextarea,
		// IonInput
	},
	methods: {
		async close() {
			console.log("closing");
			await modalController.dismiss();
		},

		async updateRating() {
			const rateTrainer = firebase.functions().httpsCallable("rateTrainer");

			rateTrainer({
				rating: this.rating,
				trainer: this.trainer,
				review: this.review,
			})
				.then((res) => {
					console.log(res, "Rating added");
					// ADD EXPERIENCE POINTS FOR RATING A TRAINER
					store
						.dispatch(Auth.addXP, { name: "RateTrainer", value: 20 })
						.catch((error) => {
							console.log("Error adding XP!", error.message);
						});
				})
				.catch((err) => console.log(err, "error occured in rating"));
			console.log("trainer modal: ", this.trainer);
			// return result;
			this.$router.back();
			await modalController.dismiss();
		},

		getIcon(ratingValue) {
			return ratingValue === this.rating ? star : starOutline;
		},
	},
});
</script>
<style scoped>
.ratingTextBox {
	/* width: 90vw;
		height: 15vh; */
	border: 1px solid white;
}

.centerLabel {
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	/* padding-top: 2vh; */
}
</style>
