<template>
	<ion-page>
		<ion-header class="ion-no-border">
			<navbar />
		</ion-header>
		<ion-content :fullscreen="true">
			<div class="page">
				<ion-card class="category-card">
					<ion-grid>
						<ion-row>
							<ion-col size="4" sizeLg="2" offsetLg="3">
								<ion-button
									size="small"
									class="text-sm"
									ref="OverviewBtn"
									color="primary"
									expand="block"
									fill="clear"
									@click="() => switchpage('Overview')"
									>Overview</ion-button
								>
							</ion-col>
							<ion-col size="4" sizeLg="2">
								<ion-button
									size="small"
									class="text-sm"
									ref="WorkoutsBtn"
									color="medium"
									expand="block"
									fill="clear"
									@click="() => switchpage('Workouts')"
									>Workouts</ion-button
								>
							</ion-col>
							<ion-col size="4" sizeLg="2">
								<ion-button
									size="small"
									class="text-sm"
									ref="AchievementsBtn"
									color="medium"
									expand="block"
									fill="clear"
									@click="() => switchpage('Achievements')"
									>Achievements</ion-button
								>
							</ion-col>
						</ion-row>
						<ion-row class="row-border"></ion-row>
					</ion-grid>
					<ion-grid v-if="currentPage == 'Overview'">
						<ion-row>
							<ion-col size="12" sizeLg="4">
								<div class="settingsbtn text-right" v-if="!isWeb">
									<ion-button size="small" fill="clear" @click="updateProfile"
										><ion-icon size="small" :icon="optionsOutline"></ion-icon
									></ion-button>
									<ion-button fill="clear" size="small" @click="takePhoto()">
										<ion-icon size="small" :icon="cameraOutline"></ion-icon>
									</ion-button>
								</div>
								<ion-avatar
									style="width: 100px; height: 100px; margin: 20px auto"
								>
									<!-- ********************************************************************* -->
									<img
										ref="UserAvatar"
										:src="userProfile.avatar || UserPhoto"
									/>
									
									<ion-button
										v-if="isWeb && !avatarLoading"
										color="light"
										shape="round"
										size="small"
										class="camerabtn"
										@click="takePhoto()"
									>
										<ion-icon size="small" :icon="cameraOutline"></ion-icon>
									</ion-button>
									<ion-button
										v-if="isWeb && avatarLoading"
										color="light"
										shape="round"
										size="small"
										class="camerabtn"
										:disabled="true"
										:v-if="avatar-loading" 
									>
										<ion-spinner name="crescent"></ion-spinner>
									</ion-button>
								</ion-avatar>
							</ion-col>
							<ion-col size="12" sizeLg="4" style="text-align: center">
								<h2 color="primary">{{ userProfile.userName }}</h2>
								<ion-badge class="pointsbadge">{{ userXP }} xp</ion-badge>
								<p>{{ userProfile.location }}</p>
								<ion-button
									size="small"
									fill="clear"
									v-if="isWeb"
									@click="updateProfile"
									>Update Profile</ion-button
								>
								<ion-button
									color="tertiary"
									v-if="isWeb"
									size="small"
									fill="clear"
									href="/settings"
									>Manage Settings</ion-button
								>
							</ion-col>
						</ion-row>
						<ion-row class="row-border"></ion-row>
						<ion-row>
							<ion-col size="3" sizeLg="2" class="text-left">
								<ion-button
									class="text-sm"
									size="small"
									fill="clear"
									@click="showFollowers"
									color="medium"
								>
									<span v-if="userProfile.followers">
										<b>{{ userProfile.followers.length }}</b> followers
									</span>
									<span v-else><b>0</b> followers</span>
								</ion-button>
							</ion-col>
							<ion-col size="3" sizeLg="2" class="text-left">
								<ion-button
									class="text-sm"
									size="small"
									fill="clear"
									@click="showFollowing"
									color="medium"
								>
									<span v-if="userProfile.following">
										<b>{{ userProfile.following.length }}</b> following
									</span>
									<span v-else><b>0</b> following</span>
								</ion-button>
							</ion-col>
							<ion-col size="6" sizeLg="8" class="text-right"
								><ion-button
									class="text-sm"
									color="medium"
									fill="clear"
									size="small"
									@click="findFriends"
									><ion-icon
										size="small"
										:icon="searchOutline"
										slot="start"
									></ion-icon
									><ion-label>Find Members</ion-label></ion-button
								></ion-col
							>
						</ion-row>
						<!-- <ion-row class="row-border"></ion-row> -->
						<ion-row>
							<ion-col class="text-center">
								<h5 class="text-primary">30 Day Activity</h5>
							</ion-col>
						</ion-row>
						<!-- <ion-row class="row-border"></ion-row> -->
						<ion-row>
							<ion-col size="12" sizeLg="6" offsetLg="3">
								<Calendar
									v-bind:is-dark="client.mode == 'dark' ? true : false"
									is-expanded
									:attributes="getCalendarData"
								/>
							</ion-col>
						</ion-row>
					</ion-grid>
					<!-- Workouts Section -->
					<ion-grid v-if="currentPage == 'Workouts'">
						<ion-row>
							<ion-col>
								<ion-item-divider color="light">
									<ion-label>
										{{ watchedHistory.length || "" }} Workouts
									</ion-label>
									<ion-button color="medium" fill="clear" slot="end">
										<ion-icon
											slot="icon-only"
											:icon="downloadOutline"
										></ion-icon
									></ion-button>
								</ion-item-divider>
								<ion-item
									color="light"
									:key="video.name + ' ' + index.toString()"
									v-for="(video, index) in watchedHistory"
								>
									<ion-avatar slot="start">
										<img :src="video.trainerImg" />
									</ion-avatar>
									<ion-label
										><h2>{{ video.className }}</h2>
										<h5 class="text-medium">
											{{ video.trainerName }} - {{ video.categoryName }}
										</h5>
										<h6>
											{{
												moment(video.startTime.toDate()).format(
													"dddd, MMMM Do YYYY, h:mm:ss a"
												)
											}}
										</h6>
									</ion-label>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>
					<ion-grid v-if="currentPage == 'Achievements'">
						
						<ion-row>
							<ion-col>
								<h5 class="text-center text-primary">Workout Milestones</h5>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col v-for="(item, index) in videoWatchBadges" :key="index">
								<ion-chip color="success" v-if="videoMilestoneReached(item)">
									<ion-icon :icon="trophy"></ion-icon>
									<ion-label>{{ item }} Workouts</ion-label>
								</ion-chip>

								<ion-chip v-else>
									<ion-icon :icon="trophy"></ion-icon>
									<ion-label>{{ item }} Workouts</ion-label>
								</ion-chip>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col>
								<h5 class="text-center text-primary">
									Daily Streaks (At least one workout per day)
								</h5>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col v-for="(item, index) in dailyWatchBadges" :key="index">
								<ion-chip color="success" v-if="dailyMilestoneReached(item)">
									<ion-icon :icon="trophy"></ion-icon>
									<ion-label>{{ item }} Day Streak</ion-label>
								</ion-chip>

								<ion-chip v-else>
									<ion-icon :icon="trophy"></ion-icon>
									<ion-label>{{ item }} Day Streak</ion-label>
								</ion-chip>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col>
								<h5 class="text-center text-primary">
									Weekly Challenge (At least one workout per week)
								</h5>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col v-for="(item, index) in weeklyWatchBadges" :key="index">
								<ion-chip color="success" v-if="weeklyMilestoneReached(item)">
									<ion-icon :icon="trophy"></ion-icon>
									<ion-label>{{ item }} Weeks</ion-label>
								</ion-chip>

								<ion-chip v-else>
									<ion-icon :icon="trophy"></ion-icon>
									<ion-label>{{ item }} Weeks</ion-label>
								</ion-chip>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col class="text-center">
								<hr>
								<ion-button
									href="/leaderboard"
									size="small"
									
									fill="solid"
									color="dark"
									>View the LeaderBoard</ion-button
								>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-card>
			</div>
		</ion-content>
		<!--  -->
		<bottombar />
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonAvatar,
	IonHeader,
	IonPage,
	IonCard,
	IonItem,
	modalController,
	IonButton,
	IonCol,
	IonRow,
	IonGrid,
	IonIcon,
	IonBadge,
	IonLabel,
	IonItemDivider,
	IonChip,
} from "@ionic/vue";
import { defineComponent } from "vue";
import modalSearch from "./modals/modalSearch.vue";
import modalFollowers from "./modals/modalFollowers.vue";
import modalFollowing from "./modals/modalFollowing.vue";
import modalUpdateProfile from "./modals/modalUpdateProfile.vue";
import Navbar from "@/components/Navbar.vue";
import Bottombar from "@/components/Bottombar.vue";
import { store } from "../store";
// import { Storage } from "@ionic/storage";
import * as fb from "../firebase";
import { ActionTypes as Auth } from "../store/modules/auth";
import { ActionTypes as VideoPlayer } from "../store/modules/video_player";
import moment from "moment";
import { toDateTime, getBlobfromImg } from "../utils/utils";
import {
	cameraOutline,
	searchOutline,
	optionsOutline,
	downloadOutline,
	trophy,
	shield,
} from "ionicons/icons";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Calendar } from "v-calendar";
import localStore from "../utils/localstore";
// import * as fb from "../firebase";

export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		Bottombar,
		IonCard,
		IonItem,
		Calendar,
		IonButton,
		IonCol,
		IonRow,
		IonGrid,
		IonIcon,
		IonBadge,
		IonLabel,
		IonItemDivider,
		IonChip,
		IonAvatar,
	},

	data() {
		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth();
		return {
			// newValue: "",
			moment,
			watchedHistory: [],
			dateOfBirth: "",
			subscriptionDate: "",
			subscriptionEndDate: "",
			// searchValue: "",
			currentPage: "Overview",
			avatarLoading: false,
			UserPhoto: "/assets/noavatar.jpg",
			videoWatchBadges: [1, 10, 25, 50, 100, 150, 200],
			dailyWatchBadges: [3, 5, 7, 10, 30, 45, 60],
			weeklyWatchBadges: [3, 5, 10, 20, 30, 40, 52],
		};
	},

	async mount() {
		const user = await localStore.get("user");
		const uid = user.uid;
		// console.log("UID", uid);
		const cool = await store
			.dispatch(Auth.userProfile, uid)
			// .then(() => true)
			.catch((error) => {
				console.log(error);
			});

		store
			.dispatch(VideoPlayer.fetchEntireWatchHistory, uid)
			.then((response) => {
				this.watchedHistory = response;
				// console.log(this.watchedHistory);
			});
		// Fetch user Xp points to update the store
		store.dispatch(Auth.fetchUserXP);
	},

	methods: {
		async takePhoto() {
			const cameraPhoto = await Camera.getPhoto({
				resultType: CameraResultType.Uri,
				source: CameraSource.Prompt,
				webUseInput: true,
				quality: 100,
			});
			const fileName = new Date().getTime() + ".jpeg";
			const savedFileImage = {
				filepath: fileName,
				webviewPath: cameraPhoto.webPath,
			};
			if (savedFileImage.webviewPath)
				this.UserPhoto = savedFileImage.webviewPath;
			//
			this.UserPhoto = savedFileImage.webviewPath;
			this.$refs.UserAvatar.src = savedFileImage.webviewPath;
			this.$refs["UserAvatar"].src = savedFileImage.webviewPath;

			this.updateAvatar();
		},

		// Update Avatar
		async updateAvatar() {
			// Get User ID
			this.avatarLoading = true;
			const user = await localStore.get("user");
			const uid = user.uid;

			const updatedAvatar = { avatar: "" };

			if (this.UserPhoto && this.UserPhoto != this.userProfile.avatar) {
				const blob = await getBlobfromImg(this.UserPhoto);
				const storageRef = fb.storage.ref();
				const fileRef = storageRef.child("profile_images/" + uid + ".jpg");
				const fileSnapshot = await fileRef.put(blob);
				const url = await fileSnapshot.ref.getDownloadURL();
				updatedAvatar["avatar"] = url;
			} else if (!this.userPhoto && this.userProfile.avatar != "") {
				const storageRef = fb.storage.ref();
				const fileRef = storageRef.child("profile_images/" + uid + ".jpg");
				await fileRef.delete();
				updatedAvatar["avatar"] = "";
			}

			// Update Avatar in Firebase
			const userDocumentSnapShot = await fb.users
				.doc(uid)
				.update(updatedAvatar);

			// // Update Store
			const updateStore = await store
				.dispatch(Auth.userProfile, uid)
				.then(() => this.avatarLoading =false)
				.catch((error) => {
					console.log(error);
				});
		},
		// User Search Modal
		async updateProfile() {
			const modal = await modalController.create({
				component: modalUpdateProfile,
				// componentProps: {},
			});
			return modal.present();
		},
		// User Search Modal
		async findFriends() {
			const modal = await modalController.create({
				component: modalSearch,
			});
			return modal.present();
		},
		async showFollowers() {
			const modal = await modalController.create({
				component: modalFollowers,
			});
			return modal.present();
		},
		async showFollowing() {
			const modal = await modalController.create({
				component: modalFollowing,
			});
			return modal.present();
		},
		switchpage(page = "") {
			// console.log(page)
			this.$refs["OverviewBtn"].$el.color = "medium";
			this.$refs["WorkoutsBtn"].$el.color = "medium";
			this.$refs["AchievementsBtn"].$el.color = "medium";
			this.$refs[page + "Btn"].$el.color = "primary";
			this.currentPage = page;
		},

		videoMilestoneReached(milestone) {
			if (this.userProfile.videoWatchMiletones) {
				const isReached = this.userProfile.videoWatchMiletones.some(
					(mile) => mile === milestone
				);

				if (isReached) {
					return true;
				}
			}

			return false;
		},

		dailyMilestoneReached(milestone) {
			if (this.userProfile.dailyWatchMilestones) {
				const isReached = this.userProfile.dailyWatchMilestones.some(
					(mile) => mile === milestone
				);

				if (isReached) {
					return true;
				}
			}

			return false;
		},

		weeklyMilestoneReached(milestone) {
			if (this.userProfile.weeklyWatchMilestones) {
				const isReached = this.userProfile.weeklyWatchMilestones.some(
					(mile) => mile === milestone
				);

				if (isReached) {
					return true;
				}
			}

			return false;
		},

		userSchedule() {
			// Get user schedule
			const _userSchedule = store.getters.getUserSchedule;
			// Create empty array
			const _attrs = [];
			// Loop through schedule to create calendar data attributes
			_userSchedule.forEach((el) => {
				// Get moment date object from array and divide it into year, month and day
				const _date = moment(el.dateMoment).toDate();
				const year = _date.getFullYear();
				const month = _date.getMonth();
				const day = _date.getDay();
				// Define calendar data attribute
				const tmpAttr = {
					key: el.classID,
					highlight: {
						fillMode: "outline",
						color: "green",
					},
					dates: new Date(year, month, day),
					popover: {
						label: el.className,
					},
				};
				_attrs.push(tmpAttr);
			});
			return _attrs;
		},

		userWatchHistory() {
			// Create empty array
			const _attrs = [];
			// Add watch history to the calendar
			this.watchedHistory.forEach((el) => {
				// console.log("WATCH HISTORY", el);
				// console.log("WATCH DATE", el.startingDate.toDate());

				// Get moment date object from array and divide it into year, month and day
				const _date = el.startTime.toDate();
				const year = _date.getFullYear();
				const month = _date.getMonth();
				const day = _date.getDay();
				// Define calendar data attribute
				const tmpAttr = {
					key: el.startingDate.seconds,
					highlight: {
						fillMode: "solid",
					},
					dates: new Date(year, month, day),
					popover: {
						label: el.className,
					},
				};
				_attrs.push(tmpAttr);
			});
			return _attrs;
		},
		//
		userExperiencePoints() {
			let _xp = 0;

			const _xp_ = store.getters.getUserXP;

			Object.keys(_xp_).forEach(function (key) {
				_xp += _xp_[key].value;
			});

			return _xp;
		},
	},

	computed: {
		getCalendarData() {
			// Create new array to store both user schedule and watch history
			const newData = this.userSchedule();
			const watchHistory = this.userWatchHistory();
			// Iterate through watch history array adding each element to the new array
			for (const el of watchHistory) {
				newData.push(el);
			}
			// console.log('CALENDAR DATA', newData)
			return newData;
		},
		isWeb() {
			return store.getters.isWeb;
		},
		client() {
			return store.getters.client;
		},
		currentRoute() {
			return this.$route.name;
		},
		userProfile() {
			const userProfileData = store.getters.userProfile;
			// console.log("Profile View", userProfileData);
			return userProfileData;
		},
		//
		userXP() {
			const xp = this.userExperiencePoints();

			return xp;
		},
	},
	watch: {
		userProfile(newProfile, oldProfile) {
			if (newProfile.dob) {
				this.dateOfBirth = moment(
					new Date(newProfile.dob.seconds * 1000)
				).format("YYYY/MM/DD");
				this.subscriptionDate = moment(
					new Date(newProfile.dob.seconds * 1000)
				).format("YYYY/MM/DD");
				this.subscriptionEndDate = moment(
					new Date(newProfile.dob.seconds * 1000)
				).format("YYYY/MM/DD");
			}
		},
	},
	setup() {
		return {
			toDateTime,
			cameraOutline,
			searchOutline,
			optionsOutline,
			downloadOutline,
			trophy,
			shield,
		};
	},
});
</script>

<style scoped>
/* ion-button {
  --background-hover: #000;
  --background-hover-opacity: 1;
  float:right;
} */
ion-card {
	box-shadow: 0px 0px 2px 4px rgba(255, 255, 255, 0.05);
}
ion-spinner {
    transform: scale(0.6);
}
.camerabtn {
	position: relative;
	top: -30px;
	right: -60px;
}
.settingsbtn {
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
}
.pointsbadge {
	/* position:absolute; */
	margin-top: -35px;
}
</style>
