
import Navbar from "../components/Navbar.vue";

import {
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonCol,
	IonGrid,
	IonCard,
	IonCardContent,isPlatform
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { key, store } from "../store";
export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		Navbar,
		IonRow,
		IonCol,
		IonGrid,
		IonCard,
		IonCardContent,
	},
	setup() {
		const subscribeLink = `${process.env.VUE_APP_CRM_SUBSCRIBE}${store.getters.userData.uid}`;
		const router = useRouter();
		return {
			subscribeLink,router
		};
	},
	methods: {
		async SkipSubscribe() {
			this.$router.push("/classes");
		},
		manageSubscription() {
			if(isPlatform('ios') || isPlatform('android')){
				this.router.push('/iap')
			} else {
				window.open(this.subscribeLink, '_self')
			}
		}
	},
	computed: {
		isWeb() {
			return store.getters.isWeb;
		},
		client() {
			return store.getters.client;
		},
	},
});
