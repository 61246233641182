import { ActionTree } from "vuex";
import { MutationTypes } from "./mutations";
import { State } from "./index";
import { RootState, store } from "@/store";
import * as fb from "../../../firebase";
import firebase from "firebase";
import { ClassData } from "@/interfaces/contentStore";
import { ClassProgress, UserWatching } from "@/interfaces/videoPlayerStore";
import { AugmentedActionContext } from "./types";
import { Actions } from "./interfaces";
import { ActionTypes as Auth } from "../auth";

// Actions
// place the action definition as a string
// ==> { setX = "MODULE__SET_ X" }
export enum ActionTypes {
	selectCurrentVideo = "SELECT_CURRENT_VIDEO",
	addUserWatchedVideo = "USER_WATCHED_VIDEO",
	addUserClassProgress = "ADD_CLASS_PROGRESS",
	setPausedTime = "PAUSED_TIME",
	setVideoComplete = "VIDEO_COMPLETE",
	fetchCurrentViewingUsers = "SEE_WHO_ELSE",
	clearWatchList = "CLEAR_VIEWERS",
	setUserCurrentlyWatching = "USER_WATCHING_STATUS",
	fetchWatchHistory = "VIDEOS_WATCHED",
	fetchEntireWatchHistory = "ENTIRE_WATCH_HISTORY",
	updateVideoWatchMilestone = "MILESTONE_VIDEO_REACHED",
	updateDailyWatchMilestone = "MILESTONE_DAILY_REACHED",
	updateWeeklyWatchMilestone = "MILESTONE_WEEKLY_REACHED",
	reset = "RESET",
}

// [C.3] declare actions
// [ActionTypes.setX]({ commit }, payload) {
//   commit(MutationTypes.X, payload);
// },
export const actions: ActionTree<State, RootState> & Actions = {
	[ActionTypes.selectCurrentVideo]({ commit }, payload: ClassData) {
		commit(MutationTypes.SET_CURRENT_VIDEO, payload);
	},

	async [ActionTypes.addUserWatchedVideo](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		const docRef = await fb.users
			.doc(payload.userID)
			.collection("classWatchHistory")
			.add({
				class: fb.db.doc("/classes/" + payload.classID),
				startTime: firebase.firestore.FieldValue.serverTimestamp(),
				categoryName: payload.video.categoryName,
				trainerImg: payload.video.trainerData.imgURL,
				trainerName: payload.video.trainerData.name,
				className: payload.video.name,
				startingDate: payload.video.date,
			});

		// console.log(docRef);
		const docData = await docRef?.get();

		commit(MutationTypes.SET_RECORD, { ...docData?.data(), id: docData?.id });
	},

	[ActionTypes.addUserClassProgress](
		{ commit }: AugmentedActionContext,
		payload: firebase.firestore.DocumentData
	) {
		// console.log(payload);
		fb.users
			.doc(payload.userID)
			.collection("classProgress")
			.doc(payload.classID)
			.set({
				classID: payload.classID,
				currentTime: 0,
				startTime: firebase.firestore.FieldValue.serverTimestamp(),
				currentlyViewing: payload.currentlyViewing,
				user: fb.users.doc(payload.userID),
			});
	},

	[ActionTypes.setPausedTime](
		{ commit }: AugmentedActionContext,
		payload: firebase.firestore.DocumentData
	) {
		fb.users
			.doc(payload.userID)
			.collection("classProgress")
			.doc(payload.classID)
			.update({
				currentTime: payload.currentTime,
				isFinished: payload.isFinished,
				currentlyViewing: payload.currentlyViewing,
			})
			.catch((error) => {
				console.log(error);
			});
	},

	[ActionTypes.setVideoComplete](
		{ commit }: AugmentedActionContext,
		payload: firebase.firestore.DocumentData
	) {
		fb.users
			.doc(payload.userID)
			.collection("classProgress")
			.doc(payload.classID)
			.update({
				isFinished: payload.isFinished,
				currentTime: payload.currentTime,
				currentlyViewing: payload.currentlyViewing,
				duration: payload.duration,
				calories: payload.calories,
				heartRate: payload.heartRate,
			});
		// console.log();
		const sendEmail = firebase.functions().httpsCallable("sendEmail");
		const content = `Thank you for joining the class ${payload.classID}. <br /> This is your activity statistics in this class.<br/> duration: ${payload.duration} <br/> duration: ${payload.calories} <br/> duration: ${payload.heartRate}<br/><br/> Platform Studio Team`;
		sendEmail({ title: "Activity Summary", content });
	},

	async [ActionTypes.fetchCurrentViewingUsers](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		// const classToFind = fb.classCollection.doc(payload.classID);
		// console.log("PAYLOAD", payload)
		return fb.db
			.collectionGroup("classProgress")
			.where("classID", "==", payload.classID)
			.where("currentlyViewing", "==", true)
			.limit(25)
			.onSnapshot(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
				const usersList: UserWatching[] = [];
				// console.log("SNAPSHOT",querySnapshot)
				for (const document of querySnapshot.docs) {
					const data = document.data();

					//we will show ourselves in the actual view
					if (data?.user.id !== payload.userID) {
						const user: firebase.firestore.DocumentData = await data.user.get();
						const uDat = user.data();
						// console.log("USER", user.data());
						const userData: UserWatching = {
							id: user.id,
							location: uDat.location ? uDat.location : "",
							wearableConnected: uDat.wearableConnected
								? uDat.wearableConnected
								: false,
							userName: uDat.userName,
							avatar: uDat.avatar ? uDat.avatar : "",
						};
						usersList.push(userData);
					}
				}
				commit(MutationTypes.SET_VIEWERS, usersList);
			});
	},

	[ActionTypes.clearWatchList]({ commit }: AugmentedActionContext) {
		commit(MutationTypes.SET_VIEWERS, [] as UserWatching[]);
	},

	[ActionTypes.setUserCurrentlyWatching](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		fb.users
			.doc(payload.userID)
			.collection("classProgress")
			.doc(payload.classID)
			.update({
				currentlyViewing: payload.currentlyViewing,
			});
	},

	async [ActionTypes.fetchWatchHistory](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		fb.users
			.doc(payload.userID)
			.collection("classProgress")
			.get()
			.then((querySnapshot: firebase.firestore.QuerySnapshot) => {
				let watchedClasses = [] as ClassProgress[];

				watchedClasses = querySnapshot.docs.map(
					(classDoc: firebase.firestore.DocumentSnapshot) => {
						const classDat = classDoc?.data();
						if (classDat) {
							const returnValue: ClassProgress = {
								classID: classDat.classID ? classDat.classID : classDoc.id,
								currentTime: classDat.currentTime ? classDat.currentTime : 0,
								currentlyViewing: classDat.currentlyViewing,
								isFinished: classDat.isFinished,
								startTime: classDat.startTime,
							};
							return returnValue;
						}
						return {} as ClassProgress;
					}
				);

				// console.log("WATCHED CLASSES", watchedClasses);
				commit(MutationTypes.SET_USER_CLASS_PROGRESS, watchedClasses);
			})
			.catch((err) => console.log("ERROR", err));
	},

	async [ActionTypes.fetchEntireWatchHistory](
		{ commit }: AugmentedActionContext,
		payload: any
	) {
		// If necessary
		// const user = await fb.users.doc(payload).get();
		// const userData = user.data();
		// console.log(userData);

		const watchHistoryCollection = await fb.users
			.doc(payload)
			.collection("classWatchHistory")
			.orderBy("startTime", "desc")
			.get();

		const watchHistory = [];
		// console.log("Watch History docs", watchHistoryCollection)
		for (const video of watchHistoryCollection.docs) {
			// console.log("I'm here", video.data())
			watchHistory.push(video.data());
		}

		return watchHistory;
	},
	async [ActionTypes.updateVideoWatchMilestone]({ commit }, payload) {
		const addToVideoChallenge = firebase
			.functions()
			.httpsCallable("addToVideoChallenge");

		const response = await addToVideoChallenge({
			class: payload.classObject,
		});

		// console.log("YAY THAT EXECUTED", response);

		store.dispatch(Auth.userProfile, payload.userID);
	},

	async [ActionTypes.updateDailyWatchMilestone]({ commit }, payload) {
		// console.log("EXECUTING FUNCT DAILY")
		const addToDailyChallenge = firebase
			.functions()
			.httpsCallable("addToDailyChallenge");
		const addToWeeklyChallenge = firebase
			.functions()
			.httpsCallable("addToWeeklyChallenge");

		const [dailyResponse, response] = await Promise.allSettled([
			addToDailyChallenge({
				class: payload.class,
			}),
			addToWeeklyChallenge({
				class: payload.class,
			}),
		]);
		// const dailyResponse = await addToDailyChallenge({
		// 	class: payload.class
		// });
		// const response = await addToWeeklyChallenge({
		// 	class: payload.class
		// });

		// console.log("YAY THAT EXECUTED", dailyResponse, response);
		// console.log("THE OTHER DATA LOL", response);

		store.dispatch(Auth.userProfile, payload.userID);
	},

	async [ActionTypes.updateWeeklyWatchMilestone]({ commit }, payload) {
		console.log("EXECUTING WEEKLY");
		const func = firebase.functions().useEmulator("localhost", 5001)

		const addToWeeklyChallenge = fb.functions
			.httpsCallable("addToWeeklyChallenge")
			
			

		const response = await addToWeeklyChallenge({
			class: payload.class,
		});

		console.log("YAY THAT EXECUTED WEEKLY", response);

		store.dispatch(Auth.userProfile, payload.userID);
	},

	// RESET
	async [ActionTypes.reset]({ commit }) {
		commit(MutationTypes.reset);
	},
};
