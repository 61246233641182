
import {
	IonContent,
	IonHeader,
	IonPage,
	IonButton,
	IonLabel,
	IonCard,
	IonCol,
	IonRow,
	IonGrid,
	IonImg,
	IonBadge,
	IonBackButton,
	modalController,
	loadingController,
	IonFab,
	alertController,
	IonSpinner,
	IonButtons,
	IonTitle,
	IonToolbar,
	IonIcon,
} from "@ionic/vue";
import { defineComponent } from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import moment, { utc } from "moment";
import {
	calendar,
	personCircle,
	playCircleOutline,
	albumsOutline,
	barbellOutline,
	starSharp,
	menuOutline,
	trophyOutline,
	filterCircleOutline,
	bookmarkOutline,
	bookmark,
} from "ionicons/icons";
import Modal from "./modals/modalVideo.vue";
import FilterModal from "./modals/modalFilter.vue";
import Navbar from "../components/Navbar.vue";
import { ActionTypes as Content } from "../store/modules/content";
import { ActionTypes as VideoPlayer } from "../store/modules/video_player";
import { useRouter } from "vue-router";
//import router from 'vue-router';
import { store } from "../store";
import localStore from "../utils/localstore";

export default defineComponent({
	name: "Videos",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonButton,
		// IonLabel,
		Navbar,
		IonCard,
		IonCol,
		IonRow,
		IonGrid,
		IonBadge,
		IonImg,
		IonFab,
		IonBackButton,
		IonSpinner,
		IonButtons,
		IonTitle,
		IonToolbar,
		IonIcon,
	},

	setup() {
		const router = useRouter();

		// const intensity = ref([]);
		// const length = ref([]);
		// const type = ref([]);

		return {
			// intensity,
			// length,
			// type,
			router,
			playCircleOutline,
			albumsOutline,
			barbellOutline,
			calendar,
			personCircle,
			starSharp,
			trophyOutline,
			menuOutline,
			filterCircleOutline,
			bookmarkOutline,
			bookmark,
			alertController,
		};
	},
	data() {
		return {
			filtercard: false,
			intensity: [] as any,
			length: [] as any,
			type: [] as any,
			classes: [],
			filteredClasses: [],
			classesLoading: false,
			toggleFilter: false,
			toggleValues: {
				live: false,
				onDemand: false,
				liveReplay: false,
				minute10: false,
				minute30: false,
				minute45: false,
				minute60: false,
				bookmarked: false,
				hideWatched: false,
				// low: false,
				// moderate: false,
				// high: false,
			},
		};
	},
	async beforeMount() {
		this.classesLoading = true;
		const user = await localStore.get("user");
		if (user) {
			const uid = user.uid;
			// console.log("UID", uid);
			store.dispatch(VideoPlayer.fetchWatchHistory, { userID: uid });
		}

		const content = this.content;

		if (!content.classes || content.classes.length == 0) {
			this.classesLoading = true;
		}

		await store.dispatch(Content.setCategories);
		await store.dispatch(Content.setClasses).then(() => {
			this.classesLoading = false;
		});

		this.classesLoading = false;
	},
	computed: {
		content() {
			const categories = store.getters.getCategories;
			const classes = store.getters.getClassesByCategory(
				this.$route.params?.categoryName
			);
			const liveClasses = store.getters.getLiveClasses;

			// console.log(classes, "Classes");
			return {
				classes: classes,
				categories: categories,
				liveClasses: liveClasses,
			};
		},
		userSchedule() {
			return store.getters.getUserSchedule;
		},
		classProgress() {
			return store.getters.classProgress;
		},

		bookmarkedClasses() {
			return store.getters.getBookmarkedClasses;
		},
		isWeb() {
			return store.getters.isWeb;
		},
	},
	watch: {
		content(newContent, oldContent) {
			//TODO: debug code - no I mean like this is for debugging and not that this code must be debugged
			// console.log("In Watcher");
			// console.log(newContent.classes);
			// console.log(newContent.categories);
			this.classesLoading = false;
			this.filteredClasses = newContent.classes;
		},
	},
	methods: {
		eventDate(eventdate: any) {
			// console.log(eventdate * 1000)
			const utcTime = utc(eventdate.seconds * 1000);
			// console.log(utcTime.local())
			const local = utcTime.local();
			const localF = local.format("YYYY-MM-DD");
			return localF;
		},
		removeBookmark(classObject: firebase.firestore.DocumentData) {
			const payload = {
				classID: classObject.classID,
				userID: store.getters.userData.uid,
			};
			store.dispatch(Content.removeClassBookmark, payload);
		},
		addBookmark(classObject: firebase.firestore.DocumentData) {
			const payload = {
				classID: classObject.classID,
				userID: store.getters.userData.uid,
			};
			store.dispatch(Content.addClassBookmark, payload);
		},

		isBookmarked(classObject: firebase.firestore.DocumentData) {
			const classID = classObject.classID;
			if (
				this.bookmarkedClasses.some(
					(classs: firebase.firestore.DocumentData) =>
						classs.classID === classID
				)
			) {
				return true;
			}

			return false;
		},

		progressType(classID: string) {
			const progress = this.classProgress.filter(
				(classs: firebase.firestore.DocumentData) => classs.classID === classID
			);
			if (progress[0].isFinished) {
				return "Watched";
			}
			return "Watching";
		},
		hasProgressed(classObject: firebase.firestore.DocumentData) {
			const classID = classObject.classID;
			if (
				this.classProgress.some(
					(classs: firebase.firestore.DocumentData) =>
						classs.classID === classID
				)
			) {
				return true;
			}

			return false;
		},

		isRegistered(classID: string) {
			const isScheduled = this.userSchedule.filter(
				(scheduleItem: firebase.firestore.DocumentData) => {
					return scheduleItem.classID === classID;
				}
			);

			if (isScheduled.length === 0) {
				return false;
			}

			return true;
		},
		loggers() {
			// console.log("INTENSE", this.intensity);
			// console.log("LONG", this.length);
			// console.log("TYPE", this.type);
		},

		async openModal(classObject: any) {
			// console.log(classObject);
			const modal = await modalController.create({
				component: Modal,
				cssClass: "modal-custom",
				componentProps: {
					title: "Join " + classObject?.name,
					content: classObject?.description || "No Description",
					videoimage: classObject?.imgURL,
					eventdate: classObject.date || {},
					eventname: classObject?.name,
					trainer: classObject?.trainerData.name,
					isLive: classObject?.isLive,
					video: classObject,
					isScheduled: this.isRegistered(classObject.classID),
				},
			});
			return modal.present();
		},

		changeCategory(categoryName: string) {
			this.$router.push(`/videos/${categoryName}`);

			// Reset Selections
			this.toggleValues["live"] = false;
			this.toggleValues["onDemand"] = false;
			this.toggleValues["liveReplay"] = false;
			this.toggleValues["minute10"] = false;
			this.toggleValues["minute30"] = false;
			this.toggleValues["minute45"] = false;
			this.toggleValues["minute60"] = false;
			this.toggleValues["bookmarked"] = false;
			this.toggleValues["hideWatched"] = false;

			// Reset Filter
			this.toggleFilter = false;
		},
		// Setter for the filter
		setFilter(finalResult: any) {
			this.filteredClasses = finalResult;
			this.toggleFilter = true;
		},
		// Clear Filter
		showAllClasses() {
			this.filteredClasses = this.content.classes;
			this.toggleFilter = false;
		},
		toggleHandler(toggleValues: any) {
			let filteredToggleValues = this.toggleValues;
			filteredToggleValues = toggleValues;
			// Update selections
			this.toggleValues = filteredToggleValues;

			const toggleCheck =
				this.toggleValues["live"] ||
				this.toggleValues["onDemand"] ||
				this.toggleValues["liveReplay"] ||
				this.toggleValues["minute10"] ||
				this.toggleValues["minute30"] ||
				this.toggleValues["minute45"] ||
				this.toggleValues["minute60"] ||
				this.toggleValues["bookmarked"] ||
				this.toggleValues["hideWatched"];

			// Check if all Toggles are set to false (XOR)
			if (!toggleCheck) {
				this.toggleFilter = false;
				// this.filteredClasses = this.classes;
			}
		},
		// Filter Modal
		async openFilterModal() {
			const modal = await modalController.create({
				component: FilterModal,
				componentProps: {
					classes: this.content.classes,
					setFilter: this.setFilter,
					showAllClasses: this.showAllClasses,
					filterBookmarked: this.filterBookmarked,
					hideWatched: this.hideWatched,
					toggleHandler: this.toggleHandler,
					toggleValuesInitial: this.toggleValues,
				},
			});
			return modal.present();
		},
		// Filter Bookmarked
		filterBookmarked(payload: any) {
			let classes = payload;
			classes = [];
			payload.forEach((item: firebase.firestore.DocumentData) => {
				if (this.isBookmarked(item)) {
					classes.push(item);
				}
			});
			return classes;
		},
		// Hide Watched Videos
		hideWatched(payload: any) {
			let classes = payload;
			classes.forEach((item: firebase.firestore.DocumentData) => {
				if (this.hasProgressed(item)) {
					const check = this.progressType(item["classID"]) || "";
					if (check === "Watched") {
						classes = classes.filter(
							(data: firebase.firestore.DocumentData) => data != item
						);
					}
				}
			});
			return classes;
		},
	},
});
